// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const CloverLogoWithTextIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 349 88" {...props} ref={ref} fill="none" color="text.primary">
      <path
        d="M142.742 72.8554C127.046 72.8554 115.332 61.2577 115.332 44.912C115.332 28.4884 127.046 16.8907 142.586 16.8907C155.94 16.8907 164.218 24.7522 167.419 34.2483L159.454 37.4396C156.955 29.6559 151.411 24.7522 142.43 24.7522C131.653 24.7522 123.766 32.9251 123.766 44.912C123.766 56.8988 131.653 64.9939 142.586 64.9939C151.489 64.9939 156.955 60.168 159.454 52.3065L167.419 55.4978C164.139 65.2274 156.018 72.8554 142.742 72.8554Z"
        fill="currentColor"
      />

      <path d="M174.326 71.9214V15.0226H182.604V71.9214H174.326Z" fill="currentColor" />

      <path
        d="M211.994 72.8554C199.343 72.8554 190.362 63.6706 190.362 51.4503C190.362 39.152 199.343 30.0451 211.994 30.0451C224.723 30.0451 233.703 39.152 233.703 51.4503C233.703 63.6706 224.723 72.8554 211.994 72.8554ZM212.072 65.4609C219.959 65.4609 225.426 59.6231 225.426 51.4503C225.426 43.1995 220.037 37.3618 212.072 37.3618C204.107 37.3618 198.64 43.1995 198.64 51.4503C198.64 59.5453 204.107 65.4609 212.072 65.4609Z"
        fill="currentColor"
      />

      <path
        d="M268.801 30.9013H277.391L261.148 71.9214H251.699L235.456 30.9013H244.046L256.463 64.0598L268.801 30.9013Z"
        fill="currentColor"
      />

      <path
        d="M287.018 54.1745C288.267 61.1799 293.656 65.5387 300.606 65.5387C306.775 65.5387 310.211 63.2815 312.085 59.0004L318.879 63.2815C315.756 68.73 310.211 72.8554 300.372 72.8554C287.408 72.8554 278.818 63.6706 278.818 51.4503C278.818 39.5412 288.111 29.9673 299.747 29.9673C312.476 29.9673 320.363 39.9304 320.363 50.5162C320.363 51.9173 320.285 53.3962 320.207 54.1745H287.018ZM287.096 47.8698H312.241C311.148 40.9423 306.385 36.6612 300.059 36.6612C293.656 36.6612 288.345 40.7866 287.096 47.8698Z"
        fill="currentColor"
      />

      <path
        d="M335.649 36.1164C338.383 32.6137 342.912 29.9673 348.3 29.9673V37.6731C340.96 37.751 336.118 42.1098 336.118 47.8698V71.9214H327.84V30.9013H335.649V36.1164Z"
        fill="currentColor"
      />

      <path
        d="M19.4345 86.5468C9.84233 86.5468 2.03835 78.7428 2.03835 69.1506C2.03835 64.6211 3.82185 60.9173 7.48793 57.8268C10.6727 55.1469 14.7068 53.3964 18.59 51.9102C21.1614 50.924 24.5208 49.702 27.6867 48.9801C35.4813 47.2108 40.8884 40.1051 40.5251 32.1218C40.3694 28.72 40.6619 24.7566 41.3885 20.3404C42.318 14.7068 44.6017 9.80923 47.9894 6.1809C51.764 2.13737 56.6332 0 62.0828 0C73.5103 0 82.81 9.29494 82.81 20.7273C82.81 26.0636 80.6774 30.428 76.2847 34.0657C72.5573 37.1562 67.6739 39.3219 62.9934 41.2705C59.1527 42.87 55.7745 43.908 52.9482 44.361C45.583 45.5405 39.9212 51.5138 39.1379 58.9309C39.0907 59.3791 39.0294 59.8415 38.9633 60.3086C38.4538 63.7341 37.5101 67.1878 36.7411 69.8065C33.4949 80.9085 27.6773 86.5374 19.4392 86.5374L19.4345 86.5468Z"
        fill="url(#paint0_linear_1_28)"
      />

      <path
        d="M66.0836 88C55.3779 88 48.8951 79.0967 46.7011 68.6174C45.6866 63.7671 44.0211 55.5998 46.9228 52.2121C50.4757 48.0601 60.5444 48.9093 66.0836 49.2349C76.7704 49.8671 85.4662 57.9117 85.4662 68.6174C85.4662 79.3231 76.7893 88 66.0836 88Z"
        fill="url(#paint1_linear_1_28)"
      />

      <path
        d="M16.377 41.6291C7.49729 39.8927 0 34.2969 0 25.252C0 16.2071 7.33215 8.87498 16.377 8.87498C25.4219 8.87498 30.3383 16.5327 32.7541 25.252C33.9148 29.4465 36.2456 38.3027 33.4429 41.2422C30.461 44.3704 21.6473 42.6576 16.377 41.6291Z"
        fill="url(#paint2_linear_1_28)"
      />

      <defs>
        <linearGradient
          id="paint0_linear_1_28"
          x1="0.324659"
          y1="13.831"
          x2="76.1592"
          y2="91.3501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#08EB99" />

          <stop offset="1" stopColor="#36BFBF" />
        </linearGradient>

        <linearGradient
          id="paint1_linear_1_28"
          x1="0.324659"
          y1="13.831"
          x2="76.1592"
          y2="91.3501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#08EB99" />

          <stop offset="1" stopColor="#36BFBF" />
        </linearGradient>

        <linearGradient
          id="paint2_linear_1_28"
          x1="0.324659"
          y1="13.831"
          x2="76.1592"
          y2="91.3501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#08EB99" />

          <stop offset="1" stopColor="#36BFBF" />
        </linearGradient>
      </defs>
    </Icon>
  );
});

export const CloverLogoWithTextIconMetadata = {
  name: 'Logo With Text',
  importName: 'CloverLogoWithTextIcon',
  component: <CloverLogoWithTextIcon aria-label="Clover Logo With Text Icon" />,
  tags: 'clover, logo, brand, company, org, organization, text',
};
