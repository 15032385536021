// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const ObjectiveCIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 128 128" {...props} ref={ref}>
      <g fill="#0b5a9d">
        <path d="M63.877 125.392c-32.671 0-60.37-27.594-60.627-60.469a59.94 59.94 0 0117.506-42.759 60.939 60.939 0 0143.279-18.36 60.081 60.081 0 0142.647 17.71 60.145 60.145 0 0118.157 42.522c.151 33.604-26.864 61.021-60.469 61.363h-.493zm.19-118.406a57.774 57.774 0 00-41.01 17.427 56.775 56.775 0 00-16.63 40.484c.236 31.159 26.495 57.286 57.43 57.286h.414c31.863-.29 57.504-26.266 57.385-58.128a56.97 56.97 0 00-17.217-40.273A56.7 56.7 0 0064.068 6.986z" />

        <path d="M16.89 82.383V46.865h8.64v3.183h-4.583v29.218h4.584v3.183l-8.642-.066zM46.213 64.272c0 6.478-3.933 10.167-9.26 10.167s-8.877-4.156-8.877-9.831c0-5.939 3.722-10.121 9.167-10.121s8.97 4.36 8.97 9.785zm-14.415.29c0 3.932 1.973 7.05 5.36 7.05s5.333-3.183 5.333-7.195c0-3.643-1.796-7.083-5.334-7.083s-5.392 3.328-5.392 7.307l.033-.08zM49.205 55.158c1.69-.29 3.407-.434 5.123-.428a9.17 9.17 0 015.537 1.223 4.062 4.062 0 012.006 3.61 4.48 4.48 0 01-3.183 4.183c2.269.46 3.9 2.46 3.9 4.775a5.016 5.016 0 01-1.861 3.978c-1.368 1.21-3.643 1.796-7.162 1.796a33.966 33.966 0 01-4.327-.257l-.033-18.88zm3.499 7.622h1.795c2.433 0 3.801-1.145 3.801-2.782 0-1.638-1.368-2.644-3.61-2.644a9.779 9.779 0 00-2.006.145l.02 5.28zm0 8.878c.618.065 1.243.092 1.86.078 2.263 0 4.262-.861 4.262-3.182s-1.94-3.183-4.373-3.183h-1.75v6.287zM69.54 54.901h3.517v12.554c0 5.334-2.577 7.116-6.365 7.116a9.313 9.313 0 01-2.973-.507l.428-2.834c.703.224 1.44.335 2.183.349 2.006 0 3.183-.921 3.183-4.262l.026-12.416zM83.067 65.357v2.434h-7.32v-2.434h7.32zM100.158 73.63c-1.585.632-3.281.921-4.978.862-6.129 0-9.851-3.834-9.851-9.707-.283-5.353 3.827-9.923 9.18-10.206.375-.02.757-.02 1.131.006a11.112 11.112 0 014.775.862l-.783 2.801a9.476 9.476 0 00-3.788-.75c-3.932 0-6.76 2.467-6.76 7.116 0 4.235 2.499 6.971 6.734 6.971a9.806 9.806 0 003.834-.717l.506 2.762zM111.2 46.766v35.61h-8.641v-3.182h4.583V49.949h-4.583v-3.183h8.64z" />
      </g>
    </Icon>
  );
});

export const ObjectiveCIconMetadata = {
  name: 'ObjectiveC',
  importName: 'ObjectiveCIcon',
  component: <ObjectiveCIcon aria-label="ObjectiveC Icon" />,
  tags: 'ObjectiveC, programming, language, logo, application',
};
