// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

// INSIGHT this icon is from Adam
export const InputFlowIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <g id="arrow-in-circle-outline">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 12.3198C1 11.8959 1.34359 11.5523 1.76744 11.5523H16.095C16.5188 11.5523 16.8624 11.8959 16.8624 12.3198C16.8624 12.7436 16.5188 13.0872 16.095 13.0872H1.76744C1.34359 13.0872 1 12.7436 1 12.3198Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.141 8.36579C12.4407 8.06609 12.9266 8.06609 13.2263 8.36579L16.6376 11.7771C16.9373 12.0768 16.9373 12.5627 16.6376 12.8624L13.2263 16.2737C12.9266 16.5734 12.4407 16.5734 12.141 16.2737C11.8413 15.974 11.8413 15.4881 12.141 15.1884L15.0096 12.3198L12.141 9.45112C11.8413 9.15141 11.8413 8.66549 12.141 8.36579Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.57851 7.45894C5.82566 3.25128 10.6639 1.1322 15.2802 2.33379C19.8965 3.53537 23.0876 7.74441 22.9982 12.5137C22.9087 17.283 19.562 21.3694 14.9039 22.397C10.2457 23.4246 5.49035 21.1256 3.40259 16.8366C3.21708 16.4555 3.37564 15.9962 3.75673 15.8106C4.13783 15.6251 4.59715 15.7837 4.78265 16.1648C6.55988 19.8158 10.6079 21.7729 14.5732 20.8981C18.5385 20.0234 21.3874 16.5448 21.4636 12.4849C21.5397 8.42501 18.8233 4.84203 14.8936 3.81917C10.9639 2.79632 6.84531 4.6002 4.9324 8.18201C4.73273 8.55588 4.26779 8.69709 3.89392 8.49742C3.52005 8.29775 3.37884 7.83281 3.57851 7.45894Z"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>
  );
});

export const InputFlowIconMetadata = {
  name: 'Input Flow',
  importName: 'InputFlowIcon',
  component: <InputFlowIcon aria-label="Input Flow Icon" />,
  tags: 'input, flow, arrow, circle, outline, data, info, information',
};
