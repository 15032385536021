// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const SwapHorizontalIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref} fill="none">
      <path
        d="M304 48L416 160L304 272M398.87 160H96M208 464L96 352L208 240M114 352H416"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export const SwapHorizontalIconMetadata = {
  name: 'Swap Horizontal',
  importName: 'SwapHorizontalIcon',
  component: <SwapHorizontalIcon aria-label="Swap Horizontal Icon" />,
  tags: 'swap, sync, exchange, switch, flip, reverse, invert, horizontal',
};
