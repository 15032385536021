// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

// INSIGHT this icon is from Guy
export const BuildingIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 12 12" {...props} ref={ref} fill="none">
      <g>
        <path
          d="M5.5 5.5H3.1C2.53995 5.5 2.25992 5.5 2.04601 5.60899C1.85785 5.70487 1.70487 5.85785 1.60899 6.04601C1.5 6.25992 1.5 6.53995 1.5 7.1V10.5M10.5 10.5V3.1C10.5 2.53995 10.5 2.25992 10.391 2.04601C10.2951 1.85785 10.1422 1.70487 9.95399 1.60899C9.74008 1.5 9.46005 1.5 8.9 1.5H7.1C6.53995 1.5 6.25992 1.5 6.04601 1.60899C5.85785 1.70487 5.70487 1.85785 5.60899 2.04601C5.5 2.25992 5.5 2.53995 5.5 3.1V10.5M11 10.5H1M7.25 3.5H8.75M7.25 5.5H8.75M7.25 7.5H8.75"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Icon>
  );
});

export const BuildingIconMetadata = {
  name: 'Building',
  importName: 'BuildingIcon',
  component: <BuildingIcon aria-label="Building Icon" />,
  tags: 'building, business, unit, office, company',
};
