// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const GridIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref} fill="none">
      <path
        d="M204 48H68C56.9543 48 48 56.9543 48 68V204C48 215.046 56.9543 224 68 224H204C215.046 224 224 215.046 224 204V68C224 56.9543 215.046 48 204 48Z"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M444 48H308C296.954 48 288 56.9543 288 68V204C288 215.046 296.954 224 308 224H444C455.046 224 464 215.046 464 204V68C464 56.9543 455.046 48 444 48Z"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M204 288H68C56.9543 288 48 296.954 48 308V444C48 455.046 56.9543 464 68 464H204C215.046 464 224 455.046 224 444V308C224 296.954 215.046 288 204 288Z"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M444 288H308C296.954 288 288 296.954 288 308V444C288 455.046 296.954 464 308 464H444C455.046 464 464 455.046 464 444V308C464 296.954 455.046 288 444 288Z"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export const GridIconMetadata = {
  name: 'Grid',
  importName: 'GridIcon',
  component: <GridIcon aria-label="Grid Icon" />,
  tags: 'grid, squares, layout, boxes, cells, applications',
};
