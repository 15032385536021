// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const ArrowTrendUpIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 13 9" {...props} ref={ref}>
      <path
        d="M0.32045 8.24872C0.185176 8.48946 0.270673 8.79428 0.511412 8.92955C0.752152 9.06482 1.05697 8.97933 1.19225 8.73859L0.32045 8.24872ZM12.4937 1.25633C12.4937 0.980191 12.2698 0.756333 11.9937 0.756333L7.49367 0.756333C7.21752 0.756333 6.99367 0.980191 6.99367 1.25633C6.99367 1.53248 7.21752 1.75633 7.49367 1.75633L11.4937 1.75633V5.75633C11.4937 6.03248 11.7175 6.25633 11.9937 6.25633C12.2698 6.25633 12.4937 6.03248 12.4937 5.75633L12.4937 1.25633ZM3.99967 5.21991L3.9431 5.7167L3.99967 5.21991ZM8.65372 5.30339L12.3472 1.60989L11.6401 0.90278L7.94661 4.59628L8.65372 5.30339ZM1.19225 8.73859L2.46572 6.47227L1.59392 5.9824L0.32045 8.24872L1.19225 8.73859ZM3.9431 5.7167L6.60313 6.01957L6.71626 5.02599L4.05623 4.72312L3.9431 5.7167ZM2.46572 6.47227C2.76135 5.94615 3.34349 5.64843 3.9431 5.7167L4.05623 4.72312C3.05687 4.60934 2.08664 5.10553 1.59392 5.9824L2.46572 6.47227ZM7.94661 4.59628C7.62339 4.9195 7.17043 5.0777 6.71626 5.02599L6.60313 6.01957C7.36008 6.10576 8.11502 5.84209 8.65372 5.30339L7.94661 4.59628Z"
        fill="#DB3B21"
      />
    </Icon>
  );
});

export const ArrowTrendUpIconMetadata = {
  name: 'Arrow Trend Up',
  importName: 'ArrowTrendUpIcon',
  component: <ArrowTrendUpIcon aria-label="Arrow Trend Up Icon" />,
  tags: 'arrow, trend, up',
};
