// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const RequirementsAnalysisIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 22 20" {...props} ref={ref}>
      <path
        d="M1 3.5L2.214 5L6.5 1M1 10.5L2.214 12L6.5 8M1 17.5L2.214 19L6.5 15"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M21 17H11M21 10H11M21 3H11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Icon>
  );
});

export const RequirementsAnalysisIconMetadata = {
  name: 'Requirements Analysis',
  importName: 'RequirementsAnalysisIcon',
  component: <RequirementsAnalysisIcon aria-label="Requirements Analysis Icon" />,
  tags: 'requirements, analysis, engine, process, data, list, task, to, do, checklist, finish, complete',
};
