// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const EditIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 20 21" {...props} ref={ref}>
      <g id="MdEdit">
        <path
          id="Vector"
          d="M2.5 14.8751V18.0001H5.625L14.8417 8.78342L11.7167 5.65842L2.5 14.8751ZM17.2583 6.36675C17.3356 6.28966 17.3969 6.19808 17.4387 6.09727C17.4805 5.99646 17.502 5.88839 17.502 5.77925C17.502 5.67011 17.4805 5.56204 17.4387 5.46123C17.3969 5.36042 17.3356 5.26885 17.2583 5.19175L15.3083 3.24175C15.2312 3.1645 15.1397 3.10321 15.0389 3.06139C14.938 3.01957 14.83 2.99805 14.7208 2.99805C14.6117 2.99805 14.5036 3.01957 14.4028 3.06139C14.302 3.10321 14.2104 3.1645 14.1333 3.24175L12.6083 4.76675L15.7333 7.89175L17.2583 6.36675Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
});

export const EditIconMetadata = {
  name: 'Edit',
  importName: 'EditIcon',
  component: <EditIcon aria-label="Edit Icon" />,
  tags: 'edit, pencil, write, create, update, change, modify',
};
