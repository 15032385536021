// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const SortIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} size={props?.size} ref={ref}>
      <path
        d="M12 22L6 16L7.425 14.575L12 19.15L16.575 14.575L18 16L12 22ZM7.45 9.4L6 8L12 2L18 8L16.55 9.4L12 4.85L7.45 9.4Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const SortIconMetadata = {
  name: 'Sort',
  importName: 'SortIcon',
  component: <SortIcon aria-label="Sort Icon" />,
  tags: 'sort, order, arrange, organize, filter, ascend, descend, ascending, descending, table',
};
