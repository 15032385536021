// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const UnknownIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 32 32" {...props} ref={ref} fill="none">
      <path
        d="M29.391 14.527L17.473 2.609C17.067 2.203 16.533 2 16 2C15.467 2 14.933 2.203 14.527 2.609L2.609 14.527C2.203 14.933 2 15.466 2 16C2 16.534 2.203 17.067 2.609 17.473L14.526 29.39C14.933 29.797 15.467 30 16 30C16.533 30 17.067 29.797 17.473 29.391L29.39 17.474C29.797 17.067 30 16.534 30 16C30 15.466 29.797 14.933 29.391 14.527ZM16 24C15.6022 24 15.2206 23.842 14.9393 23.5607C14.658 23.2794 14.5 22.8978 14.5 22.5C14.5 22.1022 14.658 21.7206 14.9393 21.4393C15.2206 21.158 15.6022 21 16 21C16.3978 21 16.7794 21.158 17.0607 21.4393C17.342 21.7206 17.5 22.1022 17.5 22.5C17.5 22.8978 17.342 23.2794 17.0607 23.5607C16.7794 23.842 16.3978 24 16 24ZM17.125 17.248V19.125H14.875V15H17C18.034 15 18.875 14.159 18.875 13.125C18.875 12.091 18.034 11.25 17 11.25H15C14.5029 11.2505 14.0263 11.4482 13.6748 11.7998C13.3232 12.1513 13.1255 12.6279 13.125 13.125V13.625H10.875V13.125C10.8763 12.0314 11.3113 10.9829 12.0846 10.2096C12.8579 9.43635 13.9064 9.00132 15 9H17C18.0936 9.00132 19.1421 9.43635 19.9154 10.2096C20.6887 10.9829 21.1237 12.0314 21.125 13.125C21.1237 14.197 20.7057 15.2265 19.9592 15.9958C19.2128 16.7652 18.1964 17.2143 17.125 17.248Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const UnknownIconMetadata = {
  name: 'Unknown',
  importName: 'UnknownIcon',
  component: <UnknownIcon aria-label="Unknown Icon" />,
  tags: 'unknown, unclear, hidden, secret, tbd, question, mark',
};
