// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const RemoveIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref} fill="none">
      <path
        d="M400 256H112"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export const RemoveIconMetadata = {
  name: 'Remove',
  importName: 'RemoveIcon',
  component: <RemoveIcon aria-label="Remove Icon" />,
  tags: 'remove, none, minus, delete, trash, clear, minimize',
};
