// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const SendIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 20 21" {...props} ref={ref}>
      <g id="IoSend">
        <path
          id="Vector"
          d="M18.6168 9.36912L18.6105 9.36638L1.92773 2.44685C1.78741 2.38812 1.63472 2.36509 1.48332 2.37981C1.33192 2.39453 1.18653 2.44656 1.06016 2.53123C0.926639 2.61871 0.816966 2.73802 0.741006 2.87842C0.665046 3.01882 0.625182 3.17589 0.625 3.33552V7.76091C0.625074 7.97914 0.701273 8.1905 0.840463 8.35856C0.979653 8.52663 1.17311 8.64088 1.3875 8.68162L10.4863 10.364C10.5221 10.3708 10.5543 10.3899 10.5776 10.4179C10.6008 10.4459 10.6135 10.4812 10.6135 10.5176C10.6135 10.5539 10.6008 10.5892 10.5776 10.6172C10.5543 10.6452 10.5221 10.6643 10.4863 10.6711L1.38789 12.3535C1.17356 12.3941 0.980115 12.5082 0.840862 12.6761C0.70161 12.844 0.625273 13.0553 0.625 13.2734V17.6996C0.624896 17.852 0.662638 18.0021 0.734839 18.1363C0.80704 18.2706 0.91144 18.3848 1.03867 18.4687C1.19172 18.5704 1.37133 18.6248 1.55508 18.625C1.68282 18.6249 1.80925 18.5992 1.92695 18.5496L18.6094 11.6695L18.6168 11.666C18.8414 11.5695 19.0327 11.4093 19.1671 11.2051C19.3016 11.001 19.3732 10.762 19.3732 10.5176C19.3732 10.2731 19.3016 10.0341 19.1671 9.82996C19.0327 9.62585 18.8414 9.46562 18.6168 9.36912Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
});

export const SendIconMetadata = {
  name: 'Send',
  importName: 'SendIcon',
  component: <SendIcon aria-label="Send Icon" />,
  tags: 'send, message, post, chat, delivery, communication',
};
