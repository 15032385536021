// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

// INSIGHT this icon is from Shay
export const TextIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 17 17" {...props} ref={ref}>
      <g id="text 1" clipPath="url(#clip0_156_1973)">
        <g id="Group">
          <path
            id="Vector"
            d="M1.32812 3.89821C1.587 3.89821 1.79688 3.68834 1.79688 3.42946V1.26587H3.96047C4.21934 1.26587 4.42922 1.05599 4.42922 0.797119C4.42922 0.538244 4.21934 0.328369 3.96047 0.328369H1.32812C1.06925 0.328369 0.859375 0.538244 0.859375 0.797119V3.42946C0.859375 3.68837 1.06925 3.89821 1.32812 3.89821Z"
            fill="currentColor"
          />

          <path
            id="Vector_2"
            d="M16.3906 0.328369H13.7583C13.4994 0.328369 13.2896 0.538244 13.2896 0.797119C13.2896 1.05599 13.4994 1.26587 13.7583 1.26587H15.9219V3.42946C15.9219 3.68834 16.1318 3.89821 16.3906 3.89821C16.6495 3.89821 16.8594 3.68834 16.8594 3.42946V0.797119C16.8594 0.538244 16.6495 0.328369 16.3906 0.328369Z"
            fill="currentColor"
          />

          <path
            id="Vector_3"
            d="M16.3906 12.7585C16.1318 12.7585 15.9219 12.9684 15.9219 13.2273V15.3909H13.7583C13.4994 15.3909 13.2896 15.6008 13.2896 15.8596C13.2896 16.1185 13.4994 16.3284 13.7583 16.3284H16.3906C16.6495 16.3284 16.8594 16.1185 16.8594 15.8596V13.2273C16.8594 12.9684 16.6495 12.7585 16.3906 12.7585Z"
            fill="currentColor"
          />

          <path
            id="Vector_4"
            d="M3.96047 15.3909H1.79688V13.2273C1.79688 12.9684 1.587 12.7585 1.32812 12.7585C1.06925 12.7585 0.859375 12.9684 0.859375 13.2273V15.8596C0.859375 16.1185 1.06925 16.3284 1.32812 16.3284H3.96047C4.21934 16.3284 4.42922 16.1185 4.42922 15.8596C4.42922 15.6008 4.21938 15.3909 3.96047 15.3909Z"
            fill="currentColor"
          />

          <path
            id="Vector_5"
            d="M13.9739 6.30901V3.3487C13.9739 3.20183 13.8548 3.08276 13.7076 3.08276H8.85949H4.01137C3.86418 3.08276 3.74512 3.20183 3.74512 3.3487V6.30901C3.74512 6.4562 3.86418 6.57526 4.01137 6.57526H5.42512C5.57199 6.57526 5.69105 6.4562 5.69105 6.30901V5.29495C5.69105 5.14776 5.81043 5.0287 5.9573 5.0287H7.62012C7.7673 5.0287 7.88637 5.14776 7.88637 5.29495V12.6118C7.88637 12.759 7.7673 12.8781 7.62012 12.8781H6.89293C6.74574 12.8781 6.62668 12.9971 6.62668 13.1443V14.5581C6.62668 14.705 6.74574 14.824 6.89293 14.824H8.85949H10.8261C10.9732 14.824 11.0923 14.705 11.0923 14.5581V13.1443C11.0923 12.9971 10.9732 12.8781 10.8261 12.8781H10.0989C9.95168 12.8781 9.83262 12.759 9.83262 12.6118V5.29495C9.83262 5.14776 9.95168 5.0287 10.0989 5.0287H11.7617C11.9086 5.0287 12.0279 5.14776 12.0279 5.29495V6.30901C12.0279 6.4562 12.147 6.57526 12.2939 6.57526H13.7076C13.8548 6.57526 13.9739 6.4562 13.9739 6.30901Z"
            fill="currentColor"
          />
        </g>
      </g>

      <defs>
        <clipPath id="clip0_156_1973">
          <rect width="16" height="16" fill="white" transform="translate(0.859375 0.328369)" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const TextIconMetadata = {
  name: 'Text',
  importName: 'TextIcon',
  component: <TextIcon aria-label="Text Icon" />,
  tags: 'text, font, type, typography, letter, character, alphabet, word, write',
};
