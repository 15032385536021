// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const ThumbsDownIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 53 53" {...props} ref={ref}>
      <path
        d="M49.6502 32.5039L47.1659 12.6289C47.0145 11.4279 46.43 10.3234 45.522 9.52287C44.6139 8.72235 43.4449 8.28083 42.2344 8.28125H6.625C5.74647 8.28125 4.90392 8.63024 4.28271 9.25146C3.66149 9.87267 3.3125 10.7152 3.3125 11.5938V29.8125C3.3125 30.691 3.66149 31.5336 4.28271 32.1548C4.90392 32.776 5.74647 33.125 6.625 33.125H15.5398L23.3614 48.7724C23.4991 49.0476 23.7107 49.279 23.9726 49.4406C24.2344 49.6022 24.5361 49.6877 24.8438 49.6875C27.0401 49.6875 29.1464 48.815 30.6995 47.262C32.2525 45.7089 33.125 43.6026 33.125 41.4062V38.0938H44.7188C45.4238 38.094 46.1209 37.9442 46.7636 37.6542C47.4063 37.3643 47.98 36.9409 48.4465 36.4122C48.9129 35.8834 49.2615 35.2615 49.4691 34.5876C49.6766 33.9138 49.7384 33.2035 49.6502 32.5039ZM14.9062 29.8125H6.625V11.5938H14.9062V29.8125ZM45.9609 34.2202C45.8066 34.3978 45.6157 34.5398 45.4012 34.6367C45.1868 34.7335 44.954 34.7828 44.7188 34.7812H31.4688C31.0295 34.7812 30.6082 34.9557 30.2976 35.2664C29.987 35.577 29.8125 35.9982 29.8125 36.4375V41.4062C29.8128 42.555 29.4151 43.6684 28.6869 44.557C27.9588 45.4455 26.9453 46.0543 25.8189 46.2798L18.2188 31.0775V11.5938H42.2344C42.6379 11.5936 43.0276 11.7408 43.3302 12.0076C43.6329 12.2745 43.8278 12.6426 43.8782 13.043L46.3626 32.918C46.3936 33.1512 46.3738 33.3883 46.3044 33.6131C46.2351 33.8379 46.1179 34.045 45.9609 34.2202Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ThumbsDownIconMetadata = {
  name: 'Thumbs Down',
  importName: 'ThumbsDownIcon',
  component: <ThumbsDownIcon aria-label="Thumbs Down Icon" />,
  tags: 'thumb, thumbs, down, dislike, negative, bad, disapprove, hand, gesture, finger, pointing',
};
