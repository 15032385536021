// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const ColorableGoogleDriveIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 94 85" {...props} ref={ref}>
      <path
        d="M7.10655 71.9812L11.2521 79.1414C12.1135 80.6491 13.3517 81.8336 14.8054 82.695C18.9693 77.4097 21.8671 73.3538 23.5004 70.5275C25.1575 67.6594 27.1943 63.1728 29.6107 57.0683C23.0983 56.2109 18.1633 55.7824 14.8057 55.7824C11.5829 55.7824 6.64793 56.2109 0 57.0683C0 58.7371 0.430711 60.406 1.29213 61.9137L7.10655 71.9812Z"
        fill="currentColor"
        opacity={0.5}
      />

      <path
        d="M79.1954 82.695C80.6494 81.8336 81.8876 80.6491 82.7486 79.1417L84.4715 76.1807L92.709 61.9137C93.5544 60.4387 93.9997 58.7684 94.0007 57.0683C87.3142 56.2109 82.3884 55.7824 79.2225 55.7824C75.8202 55.7824 70.894 56.2109 64.444 57.0683C66.8318 63.2062 68.8418 67.6928 70.4739 70.5275C72.12 73.3876 75.027 77.4431 79.1954 82.695Z"
        fill="currentColor"
        opacity={0.9}
      />

      <path
        d="M47.0003 26.9189C51.8178 21.1008 55.1379 16.6141 56.9603 13.4596C58.4279 10.9194 60.0432 6.86347 61.8057 1.29213C60.352 0.430711 58.6831 0 56.9603 0H37.0404C35.3175 0 33.649 0.484688 32.1949 1.29213C34.437 7.68193 36.3398 12.2295 37.9025 14.9346C39.6298 17.9243 42.6624 21.9189 47.0003 26.9189Z"
        fill="currentColor"
        opacity={0.7}
      />

      <path
        d="M64.39 57.0683H29.6108L14.8058 82.695C16.2591 83.5564 17.928 83.9872 19.6508 83.9872H74.35C76.0728 83.9872 77.7421 83.5025 79.1954 82.6947L64.3904 57.0683H64.39Z"
        fill="currentColor"
        opacity={0.5}
      />

      <path
        d="M47.0004 26.9189L32.1954 1.29251C30.7413 2.15393 29.5031 3.33811 28.6417 4.84578L1.29213 52.2229C0.446546 53.6978 0.00111716 55.3682 0 57.0683H29.6107L47.0004 26.9189Z"
        fill="currentColor"
        opacity={0.7}
      />

      <path
        d="M79.0342 28.5341L65.359 4.84539C64.4979 3.33772 63.2594 2.15354 61.8057 1.29211L47.0007 26.9189L64.39 57.0683H93.9471C93.9471 55.399 93.5164 53.7305 92.655 52.2229L79.0342 28.5341Z"
        fill="currentColor"
        opacity={0.9}
      />
    </Icon>
  );
});

export const ColorableGoogleDriveIconMetadata = {
  name: 'Colorable Google Drive',
  importName: 'ColorableGoogleDriveIcon',
  component: <ColorableGoogleDriveIcon aria-label="Colorable Google Drive Icon" />,
  tags: 'google, drive, alphabet, cloud, storage, file, folder, document, logo, brand, company, org, organization, colorable',
};
