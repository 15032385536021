// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

// INSIGHT this icon is from Adam
export const PIIIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <g id="user-focus-outline">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.13178 2.53488C2.80212 2.53488 2.53488 2.80212 2.53488 3.13178V7.22572C2.53488 7.64956 2.19129 7.99316 1.76744 7.99316C1.3436 7.99316 1 7.64956 1 7.22572V3.13178C1 1.95443 1.95443 1 3.13178 1H7.22481C7.64865 1 7.99225 1.3436 7.99225 1.76744C7.99225 2.19129 7.64865 2.53488 7.22481 2.53488H3.13178Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.0078 1.76744C16.0078 1.3436 16.3513 1 16.7752 1H20.8682C22.0456 1 23 1.95443 23 3.13178V7.22572C23 7.64956 22.6564 7.99316 22.2326 7.99316C21.8087 7.99316 21.4651 7.64956 21.4651 7.22572V3.13178C21.4651 2.80212 21.1979 2.53488 20.8682 2.53488H16.7752C16.3513 2.53488 16.0078 2.19129 16.0078 1.76744Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.76744 16.0078C2.19129 16.0078 2.53488 16.3513 2.53488 16.7752V20.8682C2.53488 21.1979 2.80212 21.4651 3.13178 21.4651H7.22481C7.64865 21.4651 7.99225 21.8087 7.99225 22.2326C7.99225 22.6564 7.64865 23 7.22481 23H3.13178C1.95443 23 1 22.0456 1 20.8682V16.7752C1 16.3513 1.3436 16.0078 1.76744 16.0078Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.2326 16.0078C22.6564 16.0078 23 16.3513 23 16.7752V20.8682C23 22.0456 22.0456 23 20.8682 23H16.7752C16.3513 23 16.0078 22.6564 16.0078 22.2326C16.0078 21.8087 16.3513 21.4651 16.7752 21.4651H20.8682C21.1979 21.4651 21.4651 21.1979 21.4651 20.8682V16.7752C21.4651 16.3513 21.8087 16.0078 22.2326 16.0078Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.48062 8.93023C7.48062 6.43425 9.50402 4.41085 12 4.41085C14.496 4.41085 16.5194 6.43425 16.5194 8.93023C16.5194 11.4262 14.496 13.4496 12 13.4496C9.50402 13.4496 7.48062 11.4262 7.48062 8.93023ZM12 5.94574C10.3517 5.94574 9.0155 7.28194 9.0155 8.93023C9.0155 10.5785 10.3517 11.9147 12 11.9147C13.6483 11.9147 14.9845 10.5785 14.9845 8.93023C14.9845 7.28194 13.6483 5.94574 12 5.94574Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.51157 17.8866C6.47474 15.1274 9.07756 13.2792 12 13.2792C14.9225 13.2792 17.5253 15.1274 18.4885 17.8866C18.6282 18.2868 18.417 18.7244 18.0168 18.8641C17.6167 19.0038 17.179 18.7926 17.0393 18.3925C16.2913 16.2495 14.2698 14.814 12 14.814C9.73026 14.814 7.70876 16.2495 6.9607 18.3925C6.82101 18.7926 6.38337 19.0038 5.98321 18.8641C5.58304 18.7244 5.37188 18.2868 5.51157 17.8866Z"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>
  );
});

export const PIIIconMetadata = {
  name: 'Personal Identifiable Information',
  importName: 'PIIIcon',
  component: <PIIIcon aria-label="Personal Identifiable Information Icon" />,
  tags: 'pii, personal, info, information, id, identifiable, identify, data, privacy, security, protection, sensitive, user',
};
