// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const ModifiedIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 12 12" {...props} ref={ref} fill="none">
      <g id="clock-fast-forward">
        <path
          d="M11.35 5.75L10.3503 6.75L9.34998 5.75M10.4725 6.5C10.4907 6.33583 10.5 6.169 10.5 6C10.5 3.51472 8.48528 1.5 6 1.5C3.51472 1.5 1.5 3.51472 1.5 6C1.5 8.48528 3.51472 10.5 6 10.5C7.41363 10.5 8.67502 9.84817 9.5 8.82867M6 3.5V6L7.5 7"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Icon>
  );
});

export const ModifiedIconMetadata = {
  name: 'Modified',
  importName: 'ModifiedIcon',
  component: <ModifiedIcon aria-label="Modified Icon" />,
  tags: 'modified, updated, update, modify, change, edit',
};
