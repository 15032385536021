// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const ElevationOfPrivilegeIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref} fill="none">
      <g id="Eleveation Of Privilege">
        <g id="Union">
          <mask id="path-1-inside-1_377_6629" fill="white">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.6522 4.35546C12.0439 3.72917 12.9561 3.72917 13.3478 4.35546L17.2446 10.5858C17.5076 11.0062 18.039 11.1704 18.4933 10.9716L20.9392 9.90157C21.7329 9.55429 22.5626 10.312 22.2887 11.1339L20.2279 17.3162C20.0918 17.7245 19.7097 18 19.2792 18H5.72075C5.29032 18 4.90819 17.7245 4.77207 17.3162L2.71133 11.134C2.43735 10.312 3.26706 9.55429 4.06083 9.90157L6.50671 10.9716C6.96103 11.1704 7.49239 11.0062 7.75535 10.5858L11.6522 4.35546Z"
            />
          </mask>

          <path
            d="M6.50671 10.9716L7.30835 9.13932L6.50671 10.9716ZM7.75535 10.5858L9.45099 11.6463L7.75535 10.5858ZM2.71133 11.134L0.813962 11.7664L2.71133 11.134ZM4.77207 17.3162L2.8747 17.9486L4.77207 17.3162ZM20.2279 17.3162L22.1253 17.9486L20.2279 17.3162ZM20.9392 9.90157L20.1375 8.06925L20.9392 9.90157ZM22.2887 11.1339L24.186 11.7664L22.2887 11.1339ZM13.3478 4.35546L15.0435 3.2949L13.3478 4.35546ZM17.2446 10.5858L18.9403 9.5252L17.2446 10.5858ZM18.9403 9.5252L15.0435 3.2949L11.6522 5.41602L15.549 11.6463L18.9403 9.5252ZM20.1375 8.06925L17.6916 9.13932L19.2949 12.804L21.7408 11.7339L20.1375 8.06925ZM22.1253 17.9486L24.186 11.7664L20.3913 10.5015L18.3306 16.6837L22.1253 17.9486ZM19.2792 16H5.72075V20H19.2792V16ZM0.813962 11.7664L2.8747 17.9486L6.66944 16.6837L4.6087 10.5015L0.813962 11.7664ZM7.30835 9.13932L4.86247 8.06925L3.25919 11.7339L5.70507 12.804L7.30835 9.13932ZM9.95653 3.2949L6.05971 9.5252L9.45099 11.6463L13.3478 5.41602L9.95653 3.2949ZM5.70507 12.804C7.06802 13.4002 8.6621 12.9076 9.45099 11.6463L6.05971 9.5252C6.32267 9.10477 6.85403 8.94056 7.30835 9.13932L5.70507 12.804ZM4.6087 10.5015C4.88267 11.3234 4.05297 12.0812 3.25919 11.7339L4.86247 8.06925C2.48116 7.02743 -0.00798321 9.30057 0.813962 11.7664L4.6087 10.5015ZM5.72075 16C6.15118 16 6.53332 16.2754 6.66944 16.6837L2.8747 17.9486C3.28305 19.1737 4.42947 20 5.72075 20V16ZM18.3306 16.6837C18.4667 16.2754 18.8488 16 19.2792 16V20C20.5705 20 21.7169 19.1737 22.1253 17.9486L18.3306 16.6837ZM21.7408 11.7339C20.947 12.0812 20.1173 11.3235 20.3913 10.5015L24.186 11.7664C25.008 9.30055 22.5188 7.02744 20.1375 8.06925L21.7408 11.7339ZM15.0435 3.2949C13.8683 1.41602 11.1317 1.41603 9.95653 3.2949L13.3478 5.41602C12.9561 6.04231 12.0439 6.04232 11.6522 5.41602L15.0435 3.2949ZM15.549 11.6463C16.3379 12.9076 17.932 13.4002 19.2949 12.804L17.6916 9.13932C18.146 8.94056 18.6773 9.10477 18.9403 9.5252L15.549 11.6463Z"
            fill="currentColor"
            mask="url(#path-1-inside-1_377_6629)"
          />
        </g>

        <g id="Rectangle 325">
          <mask id="path-3-inside-2_377_6629" fill="white">
            <rect x="4" y="16" width="17" height="5" rx="1" />
          </mask>

          <rect
            x="4"
            y="16"
            width="17"
            height="5"
            rx="1"
            stroke="currentColor"
            strokeWidth="4"
            mask="url(#path-3-inside-2_377_6629)"
          />
        </g>

        <circle id="Ellipse 22" cx="12.5" cy="4.5" r="1.5" fill="currentColor" />

        <circle id="Ellipse 23" cx="3.5" cy="10.5" r="1.5" fill="currentColor" />

        <circle id="Ellipse 24" cx="21.5" cy="10.5" r="1.5" fill="currentColor" />
      </g>
    </Icon>
  );
});

export const ElevationOfPrivilegeIconMetadata = {
  name: 'Elevation Of Privilege',
  importName: 'ElevationOfPrivilegeIcon',
  component: <ElevationOfPrivilegeIcon aria-label="Elevation Of Privilege Icon" />,
  tags: 'elevation, privilege, stride, security',
};
