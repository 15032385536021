// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const RepudiationIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref} fill="none">
      <path
        d="M256 288C326.692 288 384 230.692 384 160C384 89.3075 326.692 32 256 32C185.308 32 128 89.3075 128 160C128 230.692 185.308 288 256 288Z"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M143.65 227.82L48 400L134.86 399.58C137.643 399.566 140.382 400.278 142.805 401.646C145.229 403.014 147.254 404.99 148.68 407.38L192 480L280.33 285.68"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M366.54 224L464 400L377.14 399.58C374.357 399.566 371.618 400.278 369.195 401.646C366.771 403.014 364.746 404.99 363.32 407.38L320 480L256 339.2"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M256 224C291.346 224 320 195.346 320 160C320 124.654 291.346 96 256 96C220.654 96 192 124.654 192 160C192 195.346 220.654 224 256 224Z"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export const RepudiationIconMetadata = {
  name: 'Repudiation',
  importName: 'RepudiationIcon',
  component: <RepudiationIcon aria-label="Repudiation Icon" />,
  tags: 'repudiation, ribbon, stride, security',
};
