// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const ColorableConfluenceIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 94 91" {...props} ref={ref}>
      <g clipPath="url(#clip0_24_165)">
        <path
          d="M3.40017 68.7852C2.4308 70.3667 1.34135 72.2019 0.416407 73.6641C0.0184364 74.3366 -0.0998077 75.1385 0.0870839 75.8973C0.273975 76.6561 0.751087 77.3114 1.41589 77.7222L20.8118 89.6584C21.1489 89.8665 21.524 90.0054 21.9153 90.067C22.3066 90.1287 22.7063 90.1118 23.091 90.0173C23.4757 89.9229 23.8378 89.7528 24.1561 89.517C24.4744 89.2812 24.7425 88.9844 24.9449 88.6438C25.7208 87.3455 26.7202 85.6597 27.8093 83.8542C35.4931 71.1723 43.2217 72.7241 57.1568 79.3782L76.3886 88.5241C76.7491 88.6957 77.1404 88.7932 77.5393 88.8108C77.9381 88.8284 78.3365 88.7658 78.7107 88.6267C79.0849 88.4876 79.4274 88.2748 79.718 88.0009C80.0085 87.727 80.2411 87.3976 80.402 87.0322L89.6375 66.1444C89.9511 65.4274 89.9704 64.6158 89.6914 63.8846C89.4123 63.1534 88.8572 62.5611 88.1456 62.2353C84.0874 60.3256 76.0159 56.5212 68.7496 53.0149C42.6099 40.3179 20.394 41.1386 3.40017 68.7852Z"
          fill="currentColor"
          opacity={0.7}
        />

        <path
          d="M90.3703 21.3821C91.3401 19.8006 92.4291 17.9654 93.3544 16.5033C93.7524 15.8306 93.8706 15.0287 93.6836 14.2699C93.4966 13.5111 93.0195 12.8559 92.3546 12.4451L72.9586 0.508932C72.6191 0.280276 72.2361 0.123933 71.8335 0.0496285C71.4309 -0.0246765 71.0174 -0.0153543 70.6186 0.0770152C70.2197 0.169385 69.8442 0.342826 69.5153 0.586551C69.1863 0.830275 68.9111 1.13907 68.7066 1.49373C67.9307 2.79174 66.9313 4.47786 65.8418 6.28296C58.158 18.9649 50.4295 17.4135 36.4944 10.759L17.3224 1.65786C16.9619 1.4863 16.5706 1.38882 16.1717 1.37121C15.7729 1.35359 15.3745 1.41619 15.0003 1.55531C14.6261 1.69442 14.2836 1.90721 13.993 2.18109C13.7025 2.45497 13.4699 2.78435 13.309 3.14975L4.07317 24.0379C3.75967 24.755 3.74037 25.5664 4.01942 26.2975C4.29847 27.0286 4.85354 27.6209 5.56505 27.9467C9.62321 29.8568 17.6951 33.6612 24.961 37.1675C51.1602 49.8494 73.3761 48.999 90.3699 21.3821H90.3703Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
});

export const ColorableConfluenceIconMetadata = {
  name: 'Confluence Logo',
  importName: 'ColorableConfluenceIcon',
  component: <ColorableConfluenceIcon aria-label="Colorable Confluence Icon" />,
  tags: 'confluence, atlassian, cloud, storage, file, folder, document, logo, colorable, brand, company, org, organization',
};
