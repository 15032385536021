// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const PciIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 64 64" {...props} fill="none" ref={ref}>
      <g transform="matrix(.617165 0 0 .617165 0 10.89296)">
        <path d="M103.7 43.2L23.4 68.4 0 4.7 84.3 0l19.4 43.2" fill="currentColor" />

        <path
          d="M43 30.4c0 1.6-.4 3-1.1 4.3-.7 1.2-1.7 2.3-3 3.1s-2.9 1.4-4.7 1.8-3.8.6-6.1.6h-1.6v8.2H16.1V21.1h12.2c4.6 0 8.2.8 10.8 2.3s3.9 3.9 3.9 7m-10 .1c0-2.4-1.8-3.6-5.3-3.6h-1.3v7.3h1.2c1.7 0 3.1-.3 4-.9.9-.5 1.4-1.5 1.4-2.8zm35.8 17.9c-1.6.3-3.4.5-5.5.5-2.9 0-5.5-.3-7.7-1-2.3-.7-4.2-1.6-5.8-2.8s-2.8-2.7-3.6-4.4c-.8-1.8-1.2-3.7-1.2-5.8s.4-4 1.3-5.8a14.38 14.38 0 0 1 3.7-4.5c1.6-1.2 3.5-2.2 5.7-2.9s4.6-1 7.2-1c2 0 3.9.1 5.6.4 1.7.4 1.7.4 3 .9v7.3c-1-.6-2.2-1.1-3.5-1.4-1.3-.4-2.7-.6-4.1-.6-2.4 0-4.3.7-5.8 1.9-1.5 1.3-2.2 3.1-2.2 5.6 0 1.2.2 2.3.7 3.3.5.9 1.1 1.7 1.9 2.3s1.7 1.1 2.8 1.4 2.2.5 3.4.5 2.5-.1 3.7-.4 2.5-.6 3.8-1.2v6.9l-3.4.8m7.7-27.3H87v27.4H76.5zm11.6-8.6c0 1.8-.6 3.3-1.8 4.3s-2.7 1.6-4.5 1.6-3.3-.5-4.5-1.6c-1.2-1-1.8-2.5-1.8-4.3 0-1.9.6-3.3 1.8-4.4s2.7-1.6 4.5-1.6 3.3.5 4.5 1.6c1.2 1 1.8 2.5 1.8 4.4"
          fill="#fff"
        />
      </g>
    </Icon>
  );
});

export const PciIconMetadata = {
  name: 'PCI Logo',
  importName: 'PciIcon',
  component: <PciIcon aria-label="PCI Logo Icon" />,
  tags: 'pci, dss, security, frameworks, industry, standards, compliance, logo, brand, company, org, organization',
};
