// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

// INSIGHT this icon is from Shay
export const CheckIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 12 12" {...props} ref={ref}>
      <g id="Frame 9056">
        <path
          id="Vector"
          d="M4.80621 7.79117L9.97939 2.61743L10.7757 3.41322L4.80621 9.38274L1.22461 5.80114L2.0204 5.00535L4.80621 7.79117Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
});

export const CheckIconMetadata = {
  name: 'Check',
  importName: 'CheckIcon',
  component: <CheckIcon aria-label="Check Icon" />,
  tags: 'check, success, done, tick, correct, approved, completed, finished, verified, valid, true, positive, accept, confirm, agree, ok, select, mark, ticked',
};
