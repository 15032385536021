// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const ColumnsIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path
        d="M4 4H20C20.5304 4 21.0391 4.21071 21.4142 4.58579C21.7893 4.96086 22 5.46957 22 6V18C22 18.5304 21.7893 19.0391 21.4142 19.4142C21.0391 19.7893 20.5304 20 20 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V6C2 5.46957 2.21071 4.96086 2.58579 4.58579C2.96086 4.21071 3.46957 4 4 4ZM16 6V18H20V6H16ZM4 6V18H8V6H4ZM10 6V18H14V6H10Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ColumnsIconMetadata = {
  name: 'Columns',
  importName: 'ColumnsIcon',
  component: <ColumnsIcon aria-label="Columns Icon" />,
  tags: 'columns, table, grid, layout, structure, organize, arrange, align, order, sort, group, categorize, divide, separate',
};
