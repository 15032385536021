// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const JiraEpicIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="#6554C0"
        fillRule="evenodd"
        d="M2,0 L14,0 C15.1045695,-2.02906125e-16 16,0.8954305 16,2 L16,14 C16,15.1045695 15.1045695,16 14,16 L2,16 C0.8954305,16 1.3527075e-16,15.1045695 0,14 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z M11.912,7.767 C11.963,7.689 12,7.6 12,7.5 C12,7.224 11.776,7 11.5,7 L9,7 L9,3.5 C9,3.224 8.776,3 8.5,3 C8.35,3 8.22,3.069 8.128,3.173 L8.126,3.172 L4.149,8.145 C4.146,8.148 4.144,8.151 4.141,8.154 L4.113,8.19 L4.115,8.194 C4.047,8.279 4,8.382 4,8.5 C4,8.776 4.224,9 4.5,9 C4.528,9 4.551,8.989 4.577,8.984 L7,8.984 L7,12.5 C7,12.776 7.224,13 7.5,13 C7.624,13 7.734,12.95 7.821,12.876 L7.825,12.877 L7.832,12.868 C7.862,12.841 7.883,12.809 7.906,12.776 L11.84,7.863 C11.859,7.845 11.871,7.824 11.887,7.803 L11.914,7.77 L11.912,7.767 Z"
      />
    </Icon>
  );
});

export const JiraEpicIconMetadata = {
  name: 'Jira Epic',
  importName: 'JiraEpicIcon',
  component: <JiraEpicIcon aria-label="Jira Epic Icon" />,
  tags: 'jira, epic, issue, type, crm, ticket, atlassian, logo, brand, company, org, organization',
};
