// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const RepositoryIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 12 12" {...props} ref={ref} fill="none">
      <g>
        <path
          d="M1.5 1.375C1.5 1.01033 1.64487 0.660591 1.90273 0.402728C2.16059 0.144866 2.51033 0 2.875 0L10.125 0C10.2245 0 10.3198 0.0395088 10.3902 0.109835C10.4605 0.180161 10.5 0.275544 10.5 0.375V10.625C10.5 10.7245 10.4605 10.8198 10.3902 10.8902C10.3198 10.9605 10.2245 11 10.125 11H7.125C7.02554 11 6.93016 10.9605 6.85984 10.8902C6.78951 10.8198 6.75 10.7245 6.75 10.625C6.75 10.5255 6.78951 10.4302 6.85984 10.3598C6.93016 10.2895 7.02554 10.25 7.125 10.25H9.75V8.25H3C2.80109 8.25 2.61032 8.32902 2.46967 8.46967C2.32902 8.61032 2.25 8.80109 2.25 9V9.375C2.25 9.733 2.465 10.042 2.775 10.1775C2.86308 10.2192 2.93145 10.2936 2.96563 10.3849C2.9998 10.4762 2.99711 10.5771 2.9581 10.6665C2.9191 10.7558 2.84686 10.8264 2.75668 10.8634C2.6665 10.9003 2.56547 10.9007 2.475 10.8645C2.18518 10.738 1.93857 10.5297 1.76538 10.2651C1.5922 10.0006 1.49997 9.69122 1.5 9.375V1.375ZM9.75 0.75H2.875C2.53 0.75 2.25 1.03 2.25 1.375V7.7005C2.47807 7.56901 2.73674 7.49986 3 7.5H9.75V0.75Z"
          fill="currentColor"
        />

        <path
          d="M3.5 9.125C3.5 9.09185 3.51317 9.06005 3.53661 9.03661C3.56005 9.01317 3.59185 9 3.625 9H6.125C6.15815 9 6.18995 9.01317 6.21339 9.03661C6.23683 9.06005 6.25 9.09185 6.25 9.125V11.63C6.24989 11.653 6.24343 11.6755 6.23134 11.6951C6.21925 11.7147 6.20199 11.7305 6.18146 11.7409C6.16094 11.7513 6.13795 11.7558 6.11502 11.754C6.09209 11.7522 6.07011 11.744 6.0515 11.7305L4.9485 10.9285C4.92714 10.913 4.90141 10.9046 4.875 10.9046C4.84859 10.9046 4.82286 10.913 4.8015 10.9285L3.6985 11.73C3.67993 11.7435 3.65799 11.7516 3.63511 11.7535C3.61222 11.7553 3.58926 11.7509 3.56876 11.7405C3.54825 11.7302 3.53098 11.7144 3.51886 11.6949C3.50673 11.6754 3.5002 11.653 3.5 11.63V9.125Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
});

export const RepositoryIconMetadata = {
  name: 'Repository Icon',
  importName: 'RepositoryIcon',
  component: <RepositoryIcon aria-label="Repository Icon" />,
  tags: 'repository, github, git, code, version, control, system, vcs',
};
