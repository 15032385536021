// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const InfoIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const InfoIconMetadata = {
  name: 'Info',
  importName: 'InfoIcon',
  component: <InfoIcon aria-label="Info Icon" />,
  tags: 'info, information, filled, circle, round, attention, help, support, tooltip, hint, tip, note',
};
