// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const InfoDisclosureIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref} fill="none">
      <path
        d="M64 256C64 256 48 250 48 224C48 198 64 192 64 192M448 246C448 246 464 241.67 464 224C464 206.33 448 202 448 202M256 160V288M112 160V288M407.94 52.2199C407.94 52.2199 321.3 160 240 160H80C75.7565 160 71.6869 161.686 68.6863 164.686C65.6857 167.687 64 171.756 64 176V272C64 276.243 65.6857 280.313 68.6863 283.314C71.6869 286.314 75.7565 288 80 288H240C321.3 288 407.94 396.23 407.94 396.23C414 404.23 432 398.75 432 386.4V61.9999C432 49.6899 415 43.1799 407.94 52.2199Z"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M144 288V456C144 458.122 144.843 460.157 146.343 461.657C147.843 463.157 149.878 464 152 464H205C207.506 464.001 209.978 463.413 212.215 462.283C214.453 461.154 216.394 459.514 217.882 457.497C219.369 455.48 220.362 453.142 220.781 450.671C221.199 448.199 221.031 445.664 220.29 443.27C211.91 416.39 192 386.08 192 336H208C212.243 336 216.313 334.314 219.314 331.314C222.314 328.313 224 324.243 224 320V304C224 299.757 222.314 295.687 219.314 292.686C216.313 289.686 212.243 288 208 288H192"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export const InfoDisclosureIconMetadata = {
  name: 'Info Disclosure',
  importName: 'InfoDisclosureIcon',
  component: <InfoDisclosureIcon aria-label="Info Disclosure Icon" />,
  tags: 'info, disclosure, megaphone, alert, stride, security',
};
