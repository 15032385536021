// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const GithubIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 40 39" {...props} ref={ref}>
      <path
        d="M20 0C17.3736 0 14.7728 0.516905 12.3463 1.5212C9.91982 2.5255 7.71504 3.99752 5.85786 5.85322C2.10714 9.60097 0 14.684 0 19.9841C0 28.8171 5.74 36.3112 13.68 38.969C14.68 39.1289 15 38.5094 15 37.9698V34.5925C9.46 35.7916 8.28 31.9146 8.28 31.9146C7.36 29.5965 6.06 28.977 6.06 28.977C4.24 27.738 6.2 27.7779 6.2 27.7779C8.2 27.9178 9.26 29.8363 9.26 29.8363C11 32.8739 13.94 31.9746 15.08 31.495C15.26 30.196 15.78 29.3167 16.34 28.8171C11.9 28.3175 7.24 26.5989 7.24 18.9849C7.24 16.7667 8 14.9881 9.3 13.5692C9.1 13.0696 8.4 10.9913 9.5 8.29341C9.5 8.29341 11.18 7.75384 15 10.3318C16.58 9.89214 18.3 9.67232 20 9.67232C21.7 9.67232 23.42 9.89214 25 10.3318C28.82 7.75384 30.5 8.29341 30.5 8.29341C31.6 10.9913 30.9 13.0696 30.7 13.5692C32 14.9881 32.76 16.7667 32.76 18.9849C32.76 26.6189 28.08 28.2975 23.62 28.7971C24.34 29.4166 25 30.6357 25 32.4942V37.9698C25 38.5094 25.32 39.1489 26.34 38.969C34.28 36.2912 40 28.8171 40 19.9841C40 17.3598 39.4827 14.7611 38.4776 12.3365C37.4725 9.91195 35.9993 7.70891 34.1421 5.85322C32.285 3.99752 30.0802 2.5255 27.6537 1.5212C25.2272 0.516905 22.6264 0 20 0Z"
        fill="#000"
      />
    </Icon>
  );
});

export const GithubIconMetadata = {
  name: 'Github',
  importName: 'GithubIcon',
  component: <GithubIcon aria-label="Github Icon" />,
  tags: 'git, github, code, repo, repository, cat, microsoft, logo, brand, company, org, organization, version, control, vc',
};
