// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { uuid4 } from '@/utils/generators';
import { Icon } from '../icon';

export const LinearIcon = forwardRef((props: IconProps, ref) => {
  const guid = `linear-icon-${uuid4()}`;

  return (
    <Icon viewBox="0 0 256 256" {...props} ref={ref} fill="none">
      <g clipPath={`url(#clip-1-${guid})`}>
        <path
          d="M8.17398 102.613L153.387 247.826C155.507 249.946 154.484 253.546 151.537 254.096C146.572 255.023 141.557 255.656 136.517 255.992C135.979 256.026 135.441 255.944 134.937 255.753C134.433 255.562 133.977 255.265 133.597 254.883L1.11698 122.403C0.73473 122.023 0.438031 121.567 0.246766 121.063C0.0554999 120.559 -0.025915 120.021 0.00797711 119.483C0.347977 114.388 0.985977 109.376 1.90398 104.463C2.45398 101.516 6.05398 100.493 8.17398 102.613ZM4.08198 161.409C3.11198 157.795 7.38198 155.515 10.028 158.161L97.838 245.972C100.485 248.618 98.205 252.887 94.591 251.918C50.561 240.113 15.888 205.44 4.08198 161.409ZM16.808 64.164C18.041 62.029 20.955 61.701 22.698 63.445L192.556 233.3C194.3 235.044 193.973 237.958 191.836 239.191C188.231 241.272 184.527 243.176 180.736 244.896C179.306 245.546 177.626 245.218 176.516 244.106L11.893 79.487C10.782 78.375 10.454 76.697 11.103 75.266C12.8229 71.4752 14.7272 67.7708 16.809 64.166M127.86 0C198.63 0 256 57.37 256 128.14C256 165.71 239.832 199.502 214.074 222.94C212.587 224.294 210.306 224.204 208.884 222.783L33.217 47.116C31.796 45.694 31.707 43.413 33.059 41.926C56.498 16.168 90.291 0 127.86 0Z"
          fill="#8299FF"
        />
      </g>

      <defs>
        <clipPath id={`clip-1-${guid}`}>
          <rect width="256" height="256" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const LinearIconMetadata = {
  name: 'Linear',
  importName: 'LinearIcon',
  component: <LinearIcon aria-label="Linear Icon" />,
  tags: 'linear, crm, ticket, logo, brand, company, org, organization',
};
