// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const HoverIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref}>
      <path
        d="M459.15 269.75C467.307 285.234 472.332 302.173 473.939 319.6C475.545 337.027 473.702 354.599 468.514 371.314C463.326 388.028 454.896 403.556 443.704 417.01C432.512 430.464 418.778 441.581 403.288 449.725L360.506 472.266L349.986 477.799C340.321 482.885 329.617 485.682 318.699 485.974C307.782 486.266 296.943 484.046 287.02 479.484L119.943 408.104L135.676 361.428L235.039 380.622L183.581 282.842L100.738 125.431L141.095 104.199L223.939 261.656L243.873 251.171L207.352 181.726L247.687 160.506L284.207 229.951L304.142 219.466L275.942 165.868L316.3 144.636L344.5 198.234L364.445 187.658L345.091 150.772L385.437 129.598L404.791 166.483L459.139 269.784L459.15 269.75ZM73.268 146.674C64.8842 138.283 59.1743 127.596 56.8589 115.963C54.5435 104.329 55.7265 92.2706 60.2586 81.3089C64.7906 70.3472 72.4685 60.9738 82.3232 54.372C92.1778 47.7702 103.767 44.2358 115.629 44.215C125.241 44.2258 134.711 46.542 143.243 50.9694C151.775 55.3968 159.12 61.8061 164.663 69.6595C170.205 77.5129 173.783 86.5811 175.096 96.1033C176.409 105.625 175.419 115.324 172.209 124.384L182.797 144.397C189.879 132.524 193.691 118.987 193.846 105.163C194 91.3394 190.491 77.7211 183.675 65.6931C176.86 53.665 166.981 43.6562 155.042 36.6843C143.104 29.7124 129.533 26.026 115.708 26C95.4494 26.0028 75.9818 33.8642 61.4019 47.9298C46.822 61.9953 38.2669 81.1682 37.5368 101.414C36.8068 121.659 43.9587 141.398 57.4875 156.478C71.0164 171.557 89.8672 180.8 110.073 182.262L99.428 162.02C89.5324 159.242 80.5216 153.956 73.268 146.674Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const HoverIconMetadata = {
  name: 'Hover',
  importName: 'HoverIcon',
  component: <HoverIcon aria-label="Hover Icon" />,
  tags: 'hover, handle, move, moveable, movable',
};
