// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const ArrowLeftFilledIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path
        d="M12.2899 8.71005L9.69991 11.3001C9.60721 11.3926 9.53366 11.5025 9.48348 11.6234C9.4333 11.7444 9.40747 11.8741 9.40747 12.0051C9.40747 12.136 9.4333 12.2657 9.48348 12.3867C9.53366 12.5076 9.60721 12.6175 9.69991 12.7101L12.2899 15.3001C12.9199 15.9301 13.9999 15.4801 13.9999 14.5901V9.41005C13.9999 8.52005 12.9199 8.08005 12.2899 8.71005Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ArrowLeftFilledIconMetadata = {
  name: 'Arrow Left (filled)',
  importName: 'ArrowLeftFilledIcon',
  component: <ArrowLeftFilledIcon aria-label="Arrow Left Filled Icon" />,
  tags: 'left, back, previous, filled, return, arrow, direction, navigate, navigation, point, move, indicator, go',
};
