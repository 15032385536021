// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const ArrowIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5301 5.46995C10.6705 5.61058 10.7494 5.8012 10.7494 5.99995C10.7494 6.1987 10.6705 6.38933 10.5301 6.52995L5.81007 11.25H20.0001C20.199 11.25 20.3898 11.329 20.5304 11.4696C20.6711 11.6103 20.7501 11.801 20.7501 12C20.7501 12.1989 20.6711 12.3896 20.5304 12.5303C20.3898 12.6709 20.199 12.75 20.0001 12.75H5.81007L10.5301 17.4699C10.6038 17.5386 10.6629 17.6214 10.7039 17.7134C10.7448 17.8054 10.7669 17.9047 10.7687 18.0054C10.7704 18.1061 10.7519 18.2062 10.7142 18.2995C10.6765 18.3929 10.6203 18.4778 10.5491 18.549C10.4779 18.6202 10.3931 18.6764 10.2997 18.7141C10.2063 18.7518 10.1063 18.7703 10.0056 18.7685C9.90485 18.7668 9.80553 18.7447 9.71353 18.7037C9.62153 18.6627 9.53873 18.6036 9.47007 18.53L3.47007 12.53C3.32962 12.3893 3.25073 12.1987 3.25073 12C3.25073 11.8012 3.32962 11.6106 3.47007 11.47L9.47007 5.46995C9.6107 5.3295 9.80132 5.25061 10.0001 5.25061C10.1988 5.25061 10.3894 5.3295 10.5301 5.46995Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ArrowIconMetadata = {
  name: 'Arrow',
  importName: 'ArrowIcon',
  component: <ArrowIcon aria-label="Arrow Icon" />,
  tags: 'arrow, direction, navigate, navigation, point, move, indicator, go, forward, back, up, down, left, right',
};
