// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { uuid4 } from '@/utils/generators';
import { Icon } from '../icon';

export const JiraIcon = forwardRef((props: IconProps, ref) => {
  const guid = `jira-icon-${uuid4()}`;

  return (
    <Icon viewBox="0 0 256 256" ms="-1px" {...props} ref={ref}>
      <g clipPath={`url(#clip-1-${guid})`}>
        <path
          d="M244.658 0H121.707C121.707 14.72 127.555 28.8372 137.963 39.2458C148.372 49.6545 162.489 55.502 177.209 55.502H199.858V77.37C199.878 107.995 224.699 132.817 255.324 132.837V10.666C255.324 4.777 250.55 0 244.658 0Z"
          fill="#2684FF"
        />

        <path
          d="M183.822 61.262H60.8719C60.8909 91.887 85.7119 116.709 116.338 116.729H138.987V138.667C139.026 169.292 163.864 194.097 194.489 194.097V71.93C194.489 66.039 189.713 61.262 183.822 61.262Z"
          fill={`url(#linear-1-${guid})`}
        />

        <path
          d="M122.951 122.489H0C0 153.142 24.85 177.991 55.502 177.991H78.222V199.858C78.242 230.455 103.02 255.266 133.618 255.324V133.156C133.618 127.265 128.842 122.489 122.951 122.489Z"
          fill={`url(#linear-2-${guid})`}
        />
      </g>

      <defs>
        <linearGradient
          id={`linear-1-${guid}`}
          x1="13159.5"
          y1="82.6486"
          x2="7961.13"
          y2="5506.91"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.18" stopColor="#0052CC" />

          <stop offset="1" stopColor="#2684FF" />
        </linearGradient>

        <linearGradient
          id={`linear-2-${guid}`}
          x1="13450.7"
          y1="182.929"
          x2="7437.47"
          y2="6099.13"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.18" stopColor="#0052CC" />

          <stop offset="1" stopColor="#2684FF" />
        </linearGradient>

        <clipPath id={`clip-1-${guid}`}>
          <rect width="256" height="256" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const JiraIconMetadata = {
  name: 'Jira Logo',
  importName: 'JiraIcon',
  component: <JiraIcon aria-label="Jira Icon" />,
  tags: 'jira, crm, ticket, atlassian, logo, brand, company, org, organization',
};
