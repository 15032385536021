// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const FedRampIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} fill="currentColor" ref={ref}>
      <path d="M1.886 3.412A1.85 1.85 0 000 5.27v15.319h3.922v-6.094h4.934V10.66H3.922V7.336h14.595a1.67 1.67 0 010 3.324h-6.844a1.431 1.431 0 00-1.298 1.325v8.601h3.919V14.54h2.168l3.138 6.047h4.378l-3.46-6.657a5.442 5.442 0 00-2.001-10.517z" />
    </Icon>
  );
});

export const FedRampIconMetadata = {
  name: 'Fed Ramp Logo',
  importName: 'FedRampIcon',
  component: <FedRampIcon aria-label="Fed Ramp Logo Icon" />,
  tags: 'fedramp, fed, ramp, logo, brand, company, org, organization, compliance, security, frameworks, industry, standards',
};
