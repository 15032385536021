// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

// INSIGHT this icon is from Adam
export const CalendarIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.999972 6.03101C0.999972 4.75947 2.03076 3.72868 3.3023 3.72868H20.6976C21.9692 3.72868 23 4.75947 23 6.03101V20.6977C23 21.9692 21.9692 23 20.6976 23H3.3023C2.03076 23 0.999972 21.9692 0.999972 20.6977V6.03101ZM3.3023 5.26357C2.87845 5.26357 2.53486 5.60716 2.53486 6.03101V20.6977C2.53486 21.1215 2.87845 21.4651 3.3023 21.4651H20.6976C21.1215 21.4651 21.4651 21.1215 21.4651 20.6977V6.03101C21.4651 5.60716 21.1215 5.26357 20.6976 5.26357H3.3023Z"
        fill="currentColor"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.999972 9.95349C0.999972 9.52964 1.34357 9.18605 1.76741 9.18605H22.2325C22.6564 9.18605 23 9.52964 23 9.95349C23 10.3773 22.6564 10.7209 22.2325 10.7209H1.76741C1.34357 10.7209 0.999972 10.3773 0.999972 9.95349Z"
        fill="currentColor"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.22478 1C7.64862 1 7.99222 1.3436 7.99222 1.76744V6.54264C7.99222 6.96648 7.64862 7.31008 7.22478 7.31008C6.80093 7.31008 6.45734 6.96648 6.45734 6.54264V1.76744C6.45734 1.3436 6.80093 1 7.22478 1Z"
        fill="currentColor"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7752 1C17.199 1 17.5426 1.3436 17.5426 1.76744V6.54264C17.5426 6.96648 17.199 7.31008 16.7752 7.31008C16.3513 7.31008 16.0077 6.96648 16.0077 6.54264V1.76744C16.0077 1.3436 16.3513 1 16.7752 1Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const CalendarIconMetadata = {
  name: 'Calendar',
  importName: 'CalendarIcon',
  component: <CalendarIcon aria-label="Calendar Icon" />,
  tags: 'calendar, date, schedule, event, reminder, plan, time, day, month, year',
};
