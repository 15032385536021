// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const ExternalLinkIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 18 19" {...props} ref={ref}>
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 12.5H18V17C18 17.825 17.325 18.5 16.5 18.5H1.5C0.675 18.5 0 17.825 0 17V2C0 1.175 0.675 0.5 1.5 0.5H6V2H1.5V17H16.5V12.5ZM9 0.5L12.375 3.875L7.5 8.75L9.75 11L14.625 6.125L18 9.5V0.5H9Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ExternalLinkIconMetadata = {
  name: 'External Link',
  importName: 'ExternalLinkIcon',
  component: <ExternalLinkIcon aria-label="External Link Icon" />,
  tags: 'external, link, open, new, tab, navigate, go, site, url',
};
