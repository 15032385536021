// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const DesignDecisionIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path
        d="M21 1.5H3C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V21C1.5 21.3978 1.65804 21.7794 1.93934 22.0607C2.22064 22.342 2.60218 22.5 3 22.5H21C21.3978 22.5 21.7794 22.342 22.0607 22.0607C22.342 21.7794 22.5 21.3978 22.5 21V3C22.5 2.60218 22.342 2.22064 22.0607 1.93934C21.7794 1.65804 21.3978 1.5 21 1.5ZM19.5 10.5H18V7.0605L12.75 12.3105V19.5H11.25V12.3105L6 7.0605V10.5H4.5V4.5H10.5V6H7.0605L12 10.9395L16.9395 6H13.5V4.5H19.5V10.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const DesignDecisionIconMetadata = {
  name: 'Design Decision',
  importName: 'DesignDecisionIcon',
  component: <DesignDecisionIcon aria-label="Design Decision Icon" />,
  tags: 'design, decision, user, way, arrow, concept, choice, direction, strategy, business, options, solution',
};
