// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

// INSIGHT this icon is from Guy
export const WeaknessIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 14 14" {...props} ref={ref} fill="none">
      <g>
        <path
          d="M6.59276 12.6087C6.7219 12.684 6.78647 12.7217 6.87761 12.7412C6.94833 12.7564 7.05151 12.7564 7.12223 12.7412C7.21336 12.7217 7.27793 12.684 7.40708 12.6087C8.54344 11.9457 11.6666 9.86325 11.6666 7V4.21026C11.6666 3.74388 11.6666 3.51069 11.5903 3.31024C11.5229 3.13316 11.4134 2.97516 11.2713 2.84989C11.1104 2.70809 10.892 2.62621 10.4553 2.46245L7.32763 1.28956C7.20636 1.24408 7.14572 1.22134 7.08334 1.21233C7.02801 1.20433 6.97182 1.20433 6.91649 1.21233C6.85411 1.22134 6.79348 1.24408 6.6722 1.28956L3.54449 2.46245C3.1078 2.62621 2.88946 2.70809 2.72855 2.84989C2.58641 2.97516 2.47691 3.13316 2.40953 3.31024C2.33325 3.51069 2.33325 3.74388 2.33325 4.21026V7C2.33325 9.86325 5.4564 11.9457 6.59276 12.6087Z"
          stroke="currentColor"
          strokeWidth="1.16667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <rect
          id="Rectangle 336"
          x="6.70841"
          y="4.37501"
          width="0.583333"
          height="3.5"
          rx="0.291667"
          fill="#D9D9D9"
          stroke="currentColor"
          strokeWidth="0.583333"
        />

        <rect
          id="Rectangle 337"
          x="6.70841"
          y="9.04167"
          width="0.583333"
          height="0.583333"
          rx="0.291667"
          fill="#D9D9D9"
          stroke="currentColor"
          strokeWidth="0.583333"
        />
      </g>
    </Icon>
  );
});

export const WeaknessIconMetadata = {
  name: 'Weakness',
  importName: 'WeaknessIcon',
  component: <WeaknessIcon aria-label="Weakness Icon" />,
  tags: 'shield, weakness, security, secure, vulnerable, guard, defense',
};
