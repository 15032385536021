// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const GranularIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 20 20" {...props} ref={ref} fill="none">
      <mask id="path-1-inside-1_741_1952" fill="white">
        <rect x="6.66693" y="7.33301" width="7.33333" height="4" rx="0.666667" />
      </mask>

      <rect
        x="6.66693"
        y="7.33301"
        width="7.33333"
        height="4"
        rx="0.666667"
        stroke="currentColor"
        strokeWidth="2.66667"
        mask="url(#path-1-inside-1_741_1952)"
      />

      <mask id="path-2-inside-2_741_1952" fill="white">
        <rect x="6.66675" y="2" width="5.33333" height="4" rx="0.666667" />
      </mask>

      <rect
        x="6.66675"
        y="2"
        width="5.33333"
        height="4"
        rx="0.666667"
        stroke="currentColor"
        strokeWidth="2.66667"
        mask="url(#path-2-inside-2_741_1952)"
      />

      <mask id="path-3-inside-3_741_1952" fill="white">
        <rect x="6.66693" y="12.667" width="9.33333" height="4" rx="0.666667" />
      </mask>

      <rect
        x="6.66693"
        y="12.667"
        width="9.33333"
        height="4"
        rx="0.666667"
        stroke="currentColor"
        strokeWidth="2.66667"
        mask="url(#path-3-inside-3_741_1952)"
      />

      <path
        d="M7.33333 4.00049H6C4.89543 4.00049 4 4.89592 4 6.00049V12.6672C4 13.7717 4.89543 14.6672 6 14.6672H7.33333"
        stroke="currentColor"
        strokeWidth="1.33333"
      />

      <line
        x1="6.66669"
        y1="9.33415"
        x2="4.66669"
        y2="9.33415"
        stroke="currentColor"
        strokeWidth="1.33333"
      />
    </Icon>
  );
});

export const GranularIconMetadata = {
  name: 'Granular',
  importName: 'GranularIcon',
  component: <GranularIcon aria-label="Granular Icon" />,
  tags: 'granular, tree, hierarchy, structure',
};
