// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

// INSIGHT this icon is from Shay
export const LinkIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 17 17" {...props} ref={ref}>
      <g id="link 1" clipPath="url(#clip0_156_1982)">
        <g id="Group">
          <g id="Group_2">
            <path
              id="Vector"
              d="M10.6215 6.56483C10.432 6.37418 10.2219 6.20518 9.99499 6.06092C9.3941 5.67615 8.69554 5.47169 7.98201 5.47169C6.99166 5.46984 6.04155 5.86333 5.34248 6.56483L1.95109 9.95888C1.25322 10.6581 0.860977 11.6055 0.860352 12.5933C0.85904 14.6549 2.52911 16.3271 4.59062 16.3284C5.57944 16.3318 6.52868 15.9402 7.22749 15.2406L10.027 12.4411C10.0779 12.3907 10.1063 12.3219 10.1058 12.2502C10.105 12.103 9.98493 11.9843 9.83768 11.9852H9.73104C9.14614 11.9872 8.56647 11.8749 8.02469 11.6546C7.92486 11.6135 7.81014 11.6367 7.73409 11.7132L5.7211 13.7289C5.09603 14.3539 4.08259 14.3539 3.45751 13.7289C2.83244 13.1038 2.83244 12.0903 3.45751 11.4653L6.86224 8.06322C7.48682 7.43943 8.49861 7.43943 9.12318 8.06322C9.54411 8.45937 10.2007 8.45937 10.6216 8.06322C10.8027 7.88197 10.9125 7.64158 10.9309 7.386C10.9503 7.08058 10.8377 6.78148 10.6215 6.56483Z"
              fill="currentColor"
            />
          </g>
        </g>

        <g id="Group_3">
          <g id="Group_4">
            <path
              id="Vector_2"
              d="M15.7649 1.4217C14.3071 -0.0360732 11.9436 -0.0360732 10.4858 1.4217L7.689 4.21585C7.6122 4.29302 7.59005 4.4091 7.63301 4.50914C7.67547 4.60947 7.7747 4.67386 7.88362 4.67177H7.98226C8.56647 4.67071 9.14521 4.78391 9.68595 5.00505C9.78578 5.04614 9.90051 5.02296 9.97656 4.94641L11.9842 2.94142C12.6093 2.31635 13.6227 2.31635 14.2478 2.94142C14.8729 3.5665 14.8729 4.57994 14.2478 5.20502L11.7469 7.70322L11.7256 7.72722L10.8511 8.59641C10.2265 9.2202 9.21469 9.2202 8.59012 8.59641C8.16919 8.20026 7.51262 8.20026 7.09173 8.59641C6.90948 8.77903 6.7996 9.02152 6.78244 9.27894C6.76295 9.58436 6.87558 9.88346 7.09173 10.1001C7.40033 10.4101 7.76139 10.6628 8.1582 10.8467C8.21419 10.8733 8.27018 10.8947 8.32617 10.9186C8.38216 10.9426 8.4408 10.9613 8.49679 10.9826C8.55278 11.004 8.61143 11.0226 8.66742 11.0386L8.82473 11.0813C8.93137 11.1079 9.03804 11.1293 9.14733 11.1479C9.27899 11.1675 9.4116 11.1799 9.5446 11.1853H9.73123H9.74722L9.90719 11.1666C9.96584 11.1639 10.0272 11.1506 10.0965 11.1506H10.1871L10.3711 11.124L10.4564 11.108L10.6111 11.076H10.6404C11.2953 10.9115 11.8933 10.5724 12.3708 10.0948L15.7648 6.70075C17.2227 5.24298 17.2227 2.87947 15.7649 1.4217Z"
              fill="currentColor"
            />
          </g>
        </g>
      </g>

      <defs>
        <clipPath id="clip0_156_1982">
          <rect width="16" height="16" fill="white" transform="translate(0.859375 0.328369)" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const LinkIconMetadata = {
  name: 'Link',
  importName: 'LinkIcon',
  component: <LinkIcon aria-label="Link Icon" />,
  tags: 'link, open, new, navigate, go, chain',
};
