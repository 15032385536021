// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const ArrowUpIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path
        d="M4 12L5.41 13.41L11 7.83V20H13V7.83L18.58 13.42L20 12L12 4L4 12Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ArrowUpIconMetadata = {
  name: 'Arrow Up',
  importName: 'ArrowUpIcon',
  component: <ArrowUpIcon aria-label="Arrow Up Icon" />,
  tags: 'arrow, up, upload, increase, collapse, ascend',
};
