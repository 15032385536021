// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const TamperingIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref} fill="none">
      <path
        d="M80 320V144C80 135.513 83.3714 127.374 89.3726 121.373C95.3737 115.371 103.513 112 112 112C120.487 112 128.626 115.371 134.627 121.373C140.629 127.374 144 135.513 144 144V256V80C144 71.5131 147.371 63.3737 153.373 57.3726C159.374 51.3714 167.513 48 176 48C184.487 48 192.626 51.3714 198.627 57.3726C204.629 63.3737 208 71.5131 208 80V240V48C208 39.5131 211.371 31.3737 217.373 25.3726C223.374 19.3714 231.513 16 240 16C248.487 16 256.626 19.3714 262.627 25.3726C268.629 31.3737 272 39.5131 272 48V240M272 241V96C272 87.5131 275.371 79.3737 281.373 73.3726C287.374 67.3714 295.513 64 304 64C312.487 64 320.626 67.3714 326.627 73.3726C332.629 79.3737 336 87.5131 336 96V320"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M80 320C80 437.4 144 496 232 496C320 496 355.71 456.4 376 408L428.71 264C435.37 245.95 432.35 229.21 416.84 220.4C401.32 211.58 380.93 216.12 372.53 232.08L336 320"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export const TamperingIconMetadata = {
  name: 'Tampering',
  importName: 'TamperingIcon',
  component: <TamperingIcon aria-label="Tampering Icon" />,
  tags: 'tampering, hand, stride, security',
};
