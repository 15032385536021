// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const ThumbsUpIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 53 53" {...props} ref={ref}>
      <path
        d="M48.4453 16.5873C47.9789 16.0588 47.4054 15.6356 46.7629 15.3458C46.1204 15.0559 45.4236 14.9061 44.7188 14.9063H33.125V11.5938C33.125 9.39743 32.2525 7.29106 30.6995 5.73802C29.1464 4.18499 27.0401 3.3125 24.8438 3.3125C24.5361 3.31228 24.2344 3.39778 23.9726 3.55941C23.7107 3.72104 23.4991 3.95241 23.3614 4.22758L15.5398 19.875H6.625C5.74647 19.875 4.90392 20.224 4.28271 20.8452C3.66149 21.4664 3.3125 22.309 3.3125 23.1875V41.4062C3.3125 42.2848 3.66149 43.1273 4.28271 43.7485C4.90392 44.3698 5.74647 44.7188 6.625 44.7188H42.2344C43.4449 44.7192 44.6139 44.2777 45.522 43.4771C46.43 42.6766 47.0145 41.5721 47.1659 40.3711L49.6502 20.4961C49.7383 19.7964 49.6764 19.0859 49.4686 18.412C49.2609 17.738 48.912 17.116 48.4453 16.5873ZM6.625 23.1875H14.9062V41.4062H6.625V23.1875ZM46.3626 20.082L43.8782 39.957C43.8278 40.3574 43.6329 40.7255 43.3302 40.9924C43.0276 41.2592 42.6379 41.4064 42.2344 41.4062H18.2188V21.9225L25.8189 6.72023C26.9453 6.94568 27.9588 7.55448 28.6869 8.44303C29.4151 9.33158 29.8128 10.445 29.8125 11.5938V16.5625C29.8125 17.0018 29.987 17.423 30.2976 17.7336C30.6082 18.0443 31.0295 18.2188 31.4688 18.2188H44.7188C44.9538 18.2187 45.1861 18.2686 45.4004 18.3653C45.6146 18.4619 45.8058 18.603 45.9613 18.7793C46.1168 18.9555 46.233 19.1628 46.3022 19.3875C46.3714 19.6121 46.392 19.8488 46.3626 20.082Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ThumbsUpIconMetadata = {
  name: 'Thumbs Up',
  importName: 'ThumbsUpIcon',
  component: <ThumbsUpIcon aria-label="Thumbs Up Icon" />,
  tags: 'thumb, thumbs, up, like, positive, good, approve, hand, gesture, finger, pointing',
};
