// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const BeatIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 34 34" {...props} ref={ref} fill="none">
      <path
        d="M1.21423 17.1943H6.53281C6.75675 17.1917 6.97598 17.1296 7.16802 17.0144C7.36006 16.8992 7.51801 16.735 7.62566 16.5386L11.9971 7.79571C12.1048 7.57804 12.2766 7.39854 12.4893 7.28133C12.702 7.16412 12.9455 7.1148 13.1871 7.14C13.4277 7.15626 13.6573 7.24674 13.8444 7.39898C14.0314 7.55121 14.1666 7.7577 14.2314 7.99L19.6471 26.01C19.7182 26.2544 19.8643 26.4704 20.0647 26.6272C20.2652 26.7841 20.5099 26.874 20.7642 26.8843C21.0026 26.8764 21.2333 26.7985 21.4277 26.6603C21.622 26.5221 21.7714 26.3297 21.8571 26.1071L25.1842 17.9714C25.2758 17.7432 25.4333 17.5474 25.6366 17.409C25.8399 17.2706 26.0797 17.1959 26.3257 17.1943H32.7857"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export const BeatIconMetadata = {
  name: 'Beat',
  importName: 'BeatIcon',
  component: <BeatIcon aria-label="Beat Icon" />,
  tags: 'beat, update, news, notification, alert, rhythm, music, heartbeat, pulse, tempo',
};
