// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

// INSIGHT this icon is from Adam
export const InfrastructureIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <g id="module-three">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.6875 14.0625C2.0672 14.0625 2.375 14.3703 2.375 14.75V19.1635L6.49999 21.5207L9.59639 19.7512C9.92605 19.5628 10.346 19.6773 10.5344 20.007C10.7228 20.3366 10.6083 20.7566 10.2786 20.945L6.84111 22.9094C6.62975 23.0302 6.37027 23.0302 6.1589 22.9094L1.3464 20.1594C1.1322 20.037 1 19.8092 1 19.5625V14.75C1 14.3703 1.3078 14.0625 1.6875 14.0625Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.5 16.8125C6.8797 16.8125 7.1875 17.1203 7.1875 17.5V22.3125C7.1875 22.6922 6.8797 23 6.5 23C6.1203 23 5.8125 22.6922 5.8125 22.3125V17.5C5.8125 17.1203 6.1203 16.8125 6.5 16.8125Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.1589 11.4031C17.3703 11.2823 17.6297 11.2823 17.8411 11.4031L22.6536 14.1531C22.8678 14.2755 23 14.5033 23 14.75C23 14.9967 22.8678 15.2245 22.6536 15.3469L17.8411 18.0969C17.6297 18.2177 17.3703 18.2177 17.1589 18.0969L12.3464 15.3469C12.1322 15.2245 12 14.9967 12 14.75C12 14.5033 12.1322 14.2755 12.3464 14.1531L17.1589 11.4031ZM14.0732 14.75L17.5 16.7082L20.9268 14.75L17.5 12.7918L14.0732 14.75Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.6875 14.0625C13.0672 14.0625 13.375 14.3703 13.375 14.75V19.1635L17.5 21.5207L21.625 19.1635V14.75C21.625 14.3703 21.9328 14.0625 22.3125 14.0625C22.6922 14.0625 23 14.3703 23 14.75V19.5625C23 19.8092 22.8678 20.037 22.6536 20.1594L17.8411 22.9094C17.6297 23.0302 17.3703 23.0302 17.1589 22.9094L12.3464 20.1594C12.1322 20.037 12 19.8092 12 19.5625V14.75C12 14.3703 12.3078 14.0625 12.6875 14.0625Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5 16.8125C17.8797 16.8125 18.1875 17.1203 18.1875 17.5V22.3125C18.1875 22.6922 17.8797 23 17.5 23C17.1203 23 16.8125 22.6922 16.8125 22.3125V17.5C16.8125 17.1203 17.1203 16.8125 17.5 16.8125Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.6589 1.09058C11.8703 0.969806 12.1297 0.969806 12.3411 1.09058L17.1536 3.84058C17.3678 3.96299 17.5 4.19079 17.5 4.4375C17.5 4.68421 17.3678 4.91201 17.1536 5.03442L12.3411 7.78442C12.1297 7.90519 11.8703 7.90519 11.6589 7.78442L6.8464 5.03442C6.6322 4.91201 6.5 4.68421 6.5 4.4375C6.5 4.19079 6.6322 3.96299 6.8464 3.84058L11.6589 1.09058ZM8.5732 4.4375L12 6.39567L15.4268 4.4375L12 2.47933L8.5732 4.4375Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.1875 3.75C7.5672 3.75 7.875 4.0578 7.875 4.4375V8.85103L12 11.2082L16.125 8.85103V4.4375C16.125 4.0578 16.4328 3.75 16.8125 3.75C17.1922 3.75 17.5 4.0578 17.5 4.4375V9.25C17.5 9.49671 17.3678 9.72451 17.1536 9.84692L12.3411 12.5969C12.1297 12.7177 11.8703 12.7177 11.6589 12.5969L6.8464 9.84692C6.6322 9.72451 6.5 9.49671 6.5 9.25V4.4375C6.5 4.0578 6.8078 3.75 7.1875 3.75Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 6.5C12.3797 6.5 12.6875 6.8078 12.6875 7.1875V12C12.6875 12.3797 12.3797 12.6875 12 12.6875C11.6203 12.6875 11.3125 12.3797 11.3125 12V7.1875C11.3125 6.8078 11.6203 6.5 12 6.5Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.1589 11.4031C6.37027 11.2823 6.62975 11.2823 6.84111 11.4031L10.2786 13.3675C10.6083 13.5559 10.7228 13.9759 10.5344 14.3055C10.346 14.6352 9.92605 14.7497 9.59639 14.5613L6.49999 12.7918L3.0732 14.75L6.50003 16.7082L8.90895 15.3319C9.23863 15.1435 9.65858 15.2581 9.84694 15.5878C10.0353 15.9175 9.92073 16.3374 9.59105 16.5258L6.84105 18.0969C6.6297 18.2177 6.37025 18.2177 6.1589 18.0969L1.3464 15.3469C1.1322 15.2245 1 14.9967 1 14.75C1 14.5033 1.1322 14.2755 1.3464 14.1531L6.1589 11.4031Z"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>
  );
});

export const InfrastructureIconMetadata = {
  name: 'Infrastructure',
  importName: 'InfrastructureIcon',
  component: <InfrastructureIcon aria-label="Infrastructure Icon" />,
  tags: 'infra, infrastructure, server, data, application, network, cloud, system, software, tech, box, cube',
};
