// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const FilterOffIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 25 25" {...props} ref={ref}>
      <path
        d="M2.48958 1.80212L1.15625 3.12504L9.89583 11.8438L10.3854 12.5H10.4167V18.6146C10.375 18.9167 10.4792 19.2396 10.7187 19.4792L12.8125 21.573C13.2188 21.9792 13.875 21.9792 14.2812 21.573C14.5208 21.3542 14.625 21.0209 14.5833 20.7084V16.5521L21.7083 23.6771L23.0312 22.3542L14.5833 13.9063L9.80208 9.12504L4.32292 3.64587L2.48958 1.80212ZM6.46875 3.12504L15.1458 11.8125L20.6146 4.81254C20.7837 4.59538 20.8601 4.3201 20.8269 4.04684C20.7937 3.77358 20.6537 3.52457 20.4375 3.35421C20.2396 3.20837 20.0208 3.12504 19.7917 3.12504H6.46875Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const FilterOffIconMetadata = {
  name: 'Filter Off',
  importName: 'FilterOffIcon',
  component: <FilterOffIcon aria-label="Filter Off Icon" />,
  tags: 'filter, off, cancel, sort, order, arrange, table, data, list, burger',
};
