// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const GroupByIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path
        d="M2.49999 8.9L11.5 14.1C11.7 14.2 11.8 14.2 12 14.2C12.2 14.2 12.3 14.2 12.5 14.1L21.5 8.9C21.7 8.8 21.8 8.7 21.9 8.5C22.1 8 22 7.4 21.5 7.1L12.5 1.9C12.2 1.7 11.8 1.7 11.5 1.9L2.49999 7.1C2.29999 7.2 2.19999 7.3 2.09999 7.5C1.89999 8 1.99999 8.6 2.49999 8.9ZM21.5 11.1L21.3 11L12.5 16.1C12.2 16.3 11.8 16.3 11.5 16.1L2.69999 11L2.49999 11.1C1.99999 11.4 1.89999 12 2.09999 12.5C2.19999 12.7 2.29999 12.8 2.49999 12.9L11.5 18.1C11.8 18.3 12.2 18.3 12.5 18.1L21.5 12.9C22 12.6 22.1 12 21.9 11.5C21.8 11.3 21.7 11.2 21.5 11.1ZM21.5 15.1L21.3 15L12.5 20.1C12.2 20.3 11.8 20.3 11.5 20.1L2.69999 15L2.49999 15.1C1.99999 15.4 1.89999 16 2.09999 16.5C2.19999 16.7 2.29999 16.8 2.49999 16.9L11.5 22.1C11.8 22.3 12.2 22.3 12.5 22.1L21.5 16.9C22 16.6 22.1 16 21.9 15.5C21.8 15.3 21.7 15.2 21.5 15.1Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const GroupByIconMetadata = {
  name: 'Group By',
  importName: 'GroupByIcon',
  component: <GroupByIcon aria-label="Group By Icon" />,
  tags: 'group, by, sort, filter, organize, categorize, arrange, table, stack, layers',
};
