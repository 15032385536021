// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

// INSIGHT this icon is from Adam
export const APIChangeIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref} fill="none">
      <path
        d="M400 240C391.11 150.46 329 96 256 96C187 96 142.56 144.2 128 192C68 198 16 235.59 16 304C16 370 70 416 136 416H396C451 416 496 388.56 496 328C496 268.18 443 242.24 400 240Z"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export const APIChangeIconMetadata = {
  name: 'API Change',
  importName: 'APIChangeIcon',
  component: <APIChangeIcon aria-label="API Change Icon" />,
  tags: 'api, change, update, modify, configure',
};
