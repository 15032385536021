// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const ErrorIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path
        d="M16.4736 1.19995H7.52641L1.20001 7.52635V16.4736L7.52641 22.8H16.4736L22.8 16.4736V7.52635L16.4736 1.19995ZM13.2 18H10.8V15.6H13.2V18ZM13.2 13.2H10.8V5.99995H13.2V13.2Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ErrorIconMetadata = {
  name: 'Error',
  importName: 'ErrorIcon',
  component: <ErrorIcon aria-label="Error Icon" />,
  tags: 'error, caution, alert, danger, attention, exclamation, error, problem, issue, hazard, risk, alarm, notice',
};
