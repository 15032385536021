// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

// INSIGHT this icon is from Guy
export const UmbrellaIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 14 14" {...props} ref={ref} fill="none">
      <g clipPath="url(#clip0_938_10417)">
        <path
          d="M4.08341 11.3167C4.08341 12.1543 4.73633 12.8333 5.54175 12.8333C6.34716 12.8333 7.00008 12.1543 7.00008 11.3167V6.41666M7.00008 6.41666C6.06081 6.41666 4.66675 6.99999 4.66675 6.99999C4.66675 6.99999 3.85602 6.41666 2.91675 6.41666C1.97748 6.41666 1.16675 6.99999 1.16675 6.99999C1.16675 3.77833 3.77842 1.16666 7.00008 1.16666C10.2217 1.16666 12.8334 3.77833 12.8334 6.99999C12.8334 6.99999 12.0227 6.41666 11.0834 6.41666C10.1441 6.41666 9.33341 6.99999 9.33341 6.99999C9.33341 6.99999 7.93935 6.41666 7.00008 6.41666Z"
          stroke="currentColor"
          strokeWidth="1.16667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>

      <defs>
        <clipPath id="clip0_938_10417">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const UmbrellaIconMetadata = {
  name: 'Umbrella',
  importName: 'UmbrellaIcon',
  component: <UmbrellaIcon aria-label="Umbrella Icon" />,
  tags: 'umbrella, coverage, protection, security, secure, safety, guard, defense',
};
