// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const LastPageIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path
        d="M7.0001 18L5.6001 16.6L10.2001 12L5.6001 7.4L7.0001 6L13.0001 12L7.0001 18ZM16.0001 18V6H18.0001V18H16.0001Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const LastPageIconMetadata = {
  name: 'Last Page',
  importName: 'LastPageIcon',
  component: <LastPageIcon aria-label="Last Page Icon" />,
  tags: 'last, page, arrow, right, finish, end, table, pagination',
};
