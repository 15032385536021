// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const DensityLargeIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref} fill="none">
      <path
        d="M96 304H416M96 208H416M96 112H416M96 400H416"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export const DensityLargeIconMetadata = {
  name: 'Density Large',
  importName: 'DensityLargeIcon',
  component: <DensityLargeIcon aria-label="Density Large Icon" />,
  tags: 'density, large, space, room, size, scale, area',
};
