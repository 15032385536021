// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

// INSIGHT this icon is from Guy
export const FlashIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 14 14" {...props} ref={ref} fill="none">
      <g>
        <path
          d="M7.58333 1.16666L2.38785 7.40124C2.18438 7.6454 2.08264 7.76749 2.08109 7.87059C2.07974 7.96023 2.11968 8.0455 2.1894 8.10184C2.2696 8.16666 2.42852 8.16666 2.74635 8.16666H7L6.41667 12.8333L11.6122 6.59874C11.8156 6.35458 11.9174 6.23249 11.9189 6.12939C11.9203 6.03975 11.8803 5.95448 11.8106 5.89814C11.7304 5.83332 11.5715 5.83332 11.2536 5.83332H7L7.58333 1.16666Z"
          stroke="currentColor"
          strokeWidth="1.16667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Icon>
  );
});

export const FlashIconMetadata = {
  name: 'Flash',
  importName: 'FlashIcon',
  component: <FlashIcon aria-label="Flash Icon" />,
  tags: 'flash, attack, security',
};
