// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const ColorableJiraIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 53 53" {...props} ref={ref}>
      <path
        d="M25.4622 4.41669C25.4622 9.71669 29.8126 14.0229 35.0684 14.0229H38.9993V17.7771C38.9993 23.0771 43.2834 27.3613 48.5834 27.3834V6.27169C48.5834 5.77971 48.388 5.30788 48.0401 4.96C47.6922 4.61212 47.2204 4.41669 46.7284 4.41669H25.4622ZM14.9505 15.0167C14.9633 17.5546 15.9771 19.985 17.7717 21.7796C19.5664 23.5742 21.9967 24.5881 24.5347 24.6009H28.5097V28.3992C28.5224 30.9371 29.5363 33.3675 31.3309 35.1621C33.1255 36.9567 35.5559 37.9706 38.0938 37.9834V16.8496C38.0875 16.3654 37.8924 15.9029 37.55 15.5605C37.2076 15.2181 36.7451 15.023 36.2609 15.0167H14.9505ZM4.41675 25.6167C4.41675 30.9167 8.723 35.2009 14.023 35.2009H17.9538V38.9992C17.9759 44.2771 22.2601 48.5834 27.538 48.5834V27.4496C27.538 26.9576 27.3426 26.4858 26.9947 26.1379C26.6468 25.79 26.175 25.5946 25.683 25.5946L4.41675 25.6167Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ColorableJiraIconMetadata = {
  name: 'Colorable Jira Logo',
  importName: 'ColorableJiraIcon',
  component: <ColorableJiraIcon aria-label="Colorable Jira Icon" />,
  tags: 'jira, atlassian, crm, ticket, logo, colorable, brand, company, org, organization',
};
