// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { uuid4 } from '@/utils/generators';
import { Icon } from '../icon';

export const TrelloIcon = forwardRef((props: IconProps, ref) => {
  const guid = `trello-icon-${uuid4()}`;

  return (
    <Icon viewBox="0 0 256 256" {...props} ref={ref} fill="none">
      <g clipPath={`url(#clip-1-${guid})`}>
        <path
          d="M231 0H25C11.1929 0 0 11.1929 0 25V231C0 244.807 11.1929 256 25 256H231C244.807 256 256 244.807 256 231V25C256 11.1929 244.807 0 231 0Z"
          fill={`url(#linear-1-${guid})`}
        />

        <path
          d="M210.72 33.28H156.64C150.013 33.28 144.64 38.6526 144.64 45.28V133.28C144.64 139.907 150.013 145.28 156.64 145.28H210.72C217.347 145.28 222.72 139.907 222.72 133.28V45.28C222.72 38.6526 217.347 33.28 210.72 33.28Z"
          fill="white"
        />

        <path
          d="M99.36 33.28H45.28C38.6526 33.28 33.28 38.6526 33.28 45.28V197.28C33.28 203.907 38.6526 209.28 45.28 209.28H99.36C105.987 209.28 111.36 203.907 111.36 197.28V45.28C111.36 38.6526 105.987 33.28 99.36 33.28Z"
          fill="white"
        />
      </g>

      <defs>
        <linearGradient
          id={`linear-1-${guid}`}
          x1="12800"
          y1="0"
          x2="12800"
          y2="25600"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0091E6" />

          <stop offset="1" stopColor="#0079BF" />
        </linearGradient>

        <clipPath id={`clip-1-${guid}`}>
          <rect width="256" height="256" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const TrelloIconMetadata = {
  name: 'Trello',
  importName: 'TrelloIcon',
  component: <TrelloIcon aria-label="Trello Icon" />,
  tags: 'trello, crm, ticket, atlassian, logo, brand, company, org, organization',
};
