// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

// INSIGHT this icon is from Adam
export const SensitiveInfoIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 22 22" {...props} ref={ref}>
      <g id="Group 1">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.1318 1.53488C1.80213 1.53488 1.53488 1.80213 1.53488 2.1318V17.1397C1.53488 17.4694 1.80213 17.7367 2.1318 17.7367H8.9536C9.37744 17.7367 9.72104 18.0803 9.72104 18.5041C9.72104 18.928 9.37744 19.2715 8.9536 19.2715H2.1318C0.95444 19.2715 0 18.3171 0 17.1397V2.1318C0 0.95444 0.95444 0 2.1318 0H11.7996C12.3646 0.000497685 12.907 0.225262 13.3064 0.624896L15.9187 3.23628C16.3183 3.63619 16.543 4.17873 16.5428 4.74409V11.6823C16.5428 12.1062 16.1992 12.4498 15.7754 12.4498C15.3515 12.4498 15.0079 12.1062 15.0079 11.6823V4.74409C15.008 4.58579 14.9452 4.4336 14.8333 4.32161L12.2212 1.71041C12.1095 1.5985 11.9574 1.5351 11.7992 1.53488H2.1318Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.4127 14.3262C16.4426 14.3244 16.4725 14.3244 16.5024 14.3262C18.6715 14.4531 20.6441 15.6243 21.7939 17.4675C22.0687 17.8901 22.0687 18.4351 21.7939 18.8577C20.6438 20.7006 18.6715 21.8717 16.5025 21.9989C16.4726 22.0007 16.4426 22.0007 16.4127 21.9989C14.2439 21.8719 12.2716 20.7012 11.1214 18.8587C10.8465 18.4361 10.8464 17.8911 11.1212 17.4685C12.2708 15.6249 14.2434 14.4533 16.4127 14.3262ZM16.4576 15.8612C14.8495 15.9695 13.3861 16.8225 12.499 18.1634C13.3867 19.5035 14.8499 20.3557 16.4575 20.4639C18.0654 20.3555 19.5286 19.5029 20.4162 18.1625C19.5288 16.8219 18.0655 15.9694 16.4576 15.8612Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.2217 15.0773C19.5374 15.3601 19.5641 15.8453 19.2813 16.161L14.7771 21.1891C14.4943 21.5048 14.0091 21.5315 13.6934 21.2487C13.3777 20.9659 13.351 20.4807 13.6338 20.165L18.138 15.1369C18.4208 14.8212 18.906 14.7945 19.2217 15.0773Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.95611 5.54178C2.95611 5.11794 3.29971 4.77434 3.72355 4.77434H11.4549C11.8788 4.77434 12.2224 5.11794 12.2224 5.54178C12.2224 5.96563 11.8788 6.30922 11.4549 6.30922H3.72355C3.29971 6.30922 2.95611 5.96563 2.95611 5.54178ZM2.95611 9.63486C2.95611 9.21101 3.29971 8.86742 3.72355 8.86742H11.4549C11.8788 8.86742 12.2224 9.21101 12.2224 9.63486C12.2224 10.0587 11.8788 10.4023 11.4549 10.4023H3.72355C3.29971 10.4023 2.95611 10.0587 2.95611 9.63486ZM2.95611 13.7279C2.95611 13.3041 3.29971 12.9605 3.72355 12.9605H8.27142C8.69526 12.9605 9.03886 13.3041 9.03886 13.7279C9.03886 14.1518 8.69526 14.4954 8.27142 14.4954H3.72355C3.29971 14.4954 2.95611 14.1518 2.95611 13.7279Z"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>
  );
});

export const SensitiveInfoIconMetadata = {
  name: 'Sensitive Info',
  importName: 'SensitiveInfoIcon',
  component: <SensitiveInfoIcon aria-label="Sensitive Info Icon" />,
  tags: 'sensitive, info, pii, data, privacy, security, protection, sensitive, user',
};
