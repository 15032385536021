// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const ResuggestIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 53 53" {...props} ref={ref}>
      <path
        d="M46.375 26.5C46.3755 31.7252 44.3182 36.7404 40.6485 40.4602C36.9788 44.1799 31.9918 46.3048 26.7671 46.375H26.5C21.4241 46.3877 16.5382 44.4452 12.8566 40.9508C12.6985 40.8012 12.5714 40.622 12.4826 40.4234C12.3938 40.2247 12.3449 40.0105 12.3389 39.793C12.3328 39.5755 12.3697 39.3589 12.4473 39.1556C12.525 38.9523 12.6419 38.7663 12.7914 38.6082C12.941 38.4501 13.1202 38.323 13.3188 38.2342C13.5175 38.1453 13.7317 38.0965 13.9492 38.0904C14.1667 38.0844 14.3833 38.1212 14.5866 38.1989C14.7899 38.2765 14.9759 38.3935 15.134 38.543C17.502 40.7762 20.4756 42.2622 23.6832 42.8153C26.8908 43.3684 30.1903 42.964 33.1695 41.6528C36.1487 40.3416 38.6755 38.1816 40.4342 35.4427C42.1929 32.7037 43.1056 29.5073 43.0582 26.2527C43.0109 22.9981 42.0056 19.8295 40.1679 17.1429C38.3303 14.4563 35.7417 12.3708 32.7257 11.1468C29.7096 9.92276 26.3997 9.6146 23.2095 10.2608C20.0194 10.9069 17.0903 12.4788 14.7882 14.78C14.7713 14.7982 14.7534 14.8155 14.7344 14.8317L9.23152 19.875H14.9062C15.3455 19.875 15.7668 20.0495 16.0774 20.3601C16.388 20.6707 16.5625 21.092 16.5625 21.5312C16.5625 21.9705 16.388 22.3918 16.0774 22.7024C15.7668 23.013 15.3455 23.1875 14.9062 23.1875H4.96875C4.52949 23.1875 4.10821 23.013 3.7976 22.7024C3.487 22.3918 3.3125 21.9705 3.3125 21.5312V11.5937C3.3125 11.1545 3.487 10.7332 3.7976 10.4226C4.10821 10.112 4.52949 9.93749 4.96875 9.93749C5.40801 9.93749 5.82929 10.112 6.1399 10.4226C6.4505 10.7332 6.625 11.1545 6.625 11.5937V17.7633L12.4736 12.4219C15.2561 9.65021 18.7973 7.76504 22.6503 7.00433C26.5033 6.24363 30.4953 6.64148 34.1223 8.14768C37.7494 9.65387 40.8489 12.2009 43.0296 15.4672C45.2103 18.7335 46.3744 22.5726 46.375 26.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ResuggestIconMetadata = {
  name: 'Resuggest',
  importName: 'ResuggestIcon',
  component: <ResuggestIcon aria-label="Resuggest Icon" />,
  tags: 'resuggest, re, suggest, refresh, reload, sync, update, repeat, arrow, circular, loop, clockwise, redo, undo',
};
