// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const ArrowRightIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 16 17" {...props} ref={ref}>
      <path
        d="M4.32669 13.92L5.51336 15.1L12.1067 8.50001L5.50669 1.90001L4.32669 3.08001L9.74669 8.50001L4.32669 13.92Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ArrowRightIconMetadata = {
  name: 'Arrow Right',
  importName: 'ArrowRightIcon',
  component: <ArrowRightIcon aria-label="Arrow Right Icon" />,
  tags: 'arrow, right, next, forward, go',
};
