// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

// INSIGHT this icon is from Adam
export const ShieldIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 16 16" {...props} ref={ref}>
      <g id="shield-check-outline">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.33333 2.53485C1.33333 1.50312 2.1697 0.666748 3.20143 0.666748H13.1646C14.1963 0.666748 15.0327 1.50312 15.0327 2.53485V7.51644C15.0327 8.28881 14.7306 9.06577 14.3126 9.78195C13.8903 10.5053 13.3195 11.2179 12.7125 11.8744C11.4984 13.1875 10.0841 14.3351 9.25969 14.9664C8.62071 15.4557 7.74533 15.4557 7.10635 14.9664C6.28193 14.3351 4.86763 13.1875 3.6535 11.8744C3.04657 11.2179 2.47571 10.5053 2.05348 9.78195C1.63543 9.06577 1.33333 8.28881 1.33333 7.51644V2.53485ZM3.20143 1.91215C2.85752 1.91215 2.57873 2.19094 2.57873 2.53485V7.51644C2.57873 7.97653 2.76449 8.52959 3.12904 9.15411C3.48941 9.77147 3.99589 10.4102 4.56794 11.0289C5.71175 12.266 7.06191 13.3638 7.86354 13.9776C8.05571 14.1248 8.31033 14.1248 8.5025 13.9776C9.30413 13.3638 10.6543 12.266 11.7981 11.0289C12.3701 10.4102 12.8766 9.77147 13.237 9.15411C13.6016 8.52959 13.7873 7.97653 13.7873 7.51644V2.53485C13.7873 2.19094 13.5085 1.91215 13.1646 1.91215H3.20143Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.0243 3.88821C11.3085 4.08176 11.3821 4.46911 11.1885 4.75338L7.93501 9.53222C7.74484 9.81833 7.41796 10.0199 7.0366 10.0066C6.65715 9.99348 6.34644 9.77215 6.17421 9.47919L5.1598 7.80662C4.98145 7.51257 5.07525 7.12962 5.36931 6.95128C5.66336 6.77294 6.04631 6.86674 6.22465 7.16079L7.08129 8.57322L10.1591 4.0525C10.3526 3.76823 10.74 3.69467 11.0243 3.88821Z"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>
  );
});

export const ShieldIconMetadata = {
  name: 'Shield',
  importName: 'ShieldIcon',
  component: <ShieldIcon aria-label="Shield Icon" />,
  tags: 'shield, protection, security, secure, safety, guard, defense',
};
