// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const AzureDevOpsIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 128 128" {...props} ref={ref} fill="none">
      <path
        d="M120.89 28.445V97.707L92.4449 121.031L48.3549 104.961V120.891L23.3949 88.25L96.1409 93.938V31.574L120.89 28.445ZM96.6399 31.93L55.8199 7.10999V23.395L18.3479 34.418L7.10889 48.852V81.637L23.1839 88.747V46.718L96.6399 31.93Z"
        fill="#008AD7"
      />
    </Icon>
  );
});

export const AzureDevOpsIconMetadata = {
  name: 'Azure DevOps',
  importName: 'AzureDevOpsIcon',
  component: <AzureDevOpsIcon aria-label="Azure DevOps Icon" />,
  tags: 'azure, devops, code, repo, repository, cat, microsoft, logo, brand, company, org, organization, version, control, vc',
};
