// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const ScanIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref} fill="none">
      <path
        d="M336 448H392C406.852 448 421.096 442.1 431.598 431.598C442.1 421.096 448 406.852 448 392V336M448 176V120C448 105.148 442.1 90.9041 431.598 80.402C421.096 69.9 406.852 64 392 64H336M176 448H120C105.148 448 90.9041 442.1 80.402 431.598C69.9 421.096 64 406.852 64 392V336M64 176V120C64 105.148 69.9 90.9041 80.402 80.402C90.9041 69.9 105.148 64 120 64H176"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export const ScanIconMetadata = {
  name: 'Scan',
  importName: 'ScanIcon',
  component: <ScanIcon aria-label="Scan Icon" />,
  tags: 'scan, fullscreen, expand, square, zoom, fit, view',
};
