// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const CloudIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 20 16" {...props} ref={ref}>
      <path
        d="M5 15.5C3.62121 15.5 2.44333 15.0078 1.46636 14.0234C0.489394 13.0391 0.000606061 11.8359 0 10.4141C0 9.19531 0.356061 8.10938 1.06818 7.15625C1.7803 6.20313 2.71212 5.59375 3.86364 5.32812C4.24242 3.89062 5 2.72656 6.13636 1.83594C7.27273 0.945313 8.56061 0.5 10 0.5C11.7727 0.5 13.2767 1.13688 14.5118 2.41062C15.747 3.68437 16.3642 5.235 16.3636 7.0625C17.4091 7.1875 18.2767 7.6525 18.9664 8.4575C19.6561 9.2625 20.0006 10.2037 20 11.2812C20 12.4531 19.6024 13.4494 18.8073 14.27C18.0121 15.0906 17.0461 15.5006 15.9091 15.5H5ZM5 13.625H15.9091C16.5455 13.625 17.0833 13.3984 17.5227 12.9453C17.9621 12.4922 18.1818 11.9375 18.1818 11.2812C18.1818 10.625 17.9621 10.0703 17.5227 9.61719C17.0833 9.16406 16.5455 8.9375 15.9091 8.9375H14.5455V7.0625C14.5455 5.76562 14.1021 4.66 13.2155 3.74562C12.3288 2.83125 11.257 2.37437 10 2.375C8.74303 2.37563 7.67121 2.83281 6.78455 3.74656C5.89788 4.66031 5.45455 5.76562 5.45455 7.0625H5C4.12121 7.0625 3.37121 7.38281 2.75 8.02344C2.12879 8.66406 1.81818 9.4375 1.81818 10.3438C1.81818 11.25 2.12879 12.0234 2.75 12.6641C3.37121 13.3047 4.12121 13.625 5 13.625Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const CloudIconMetadata = {
  name: 'Cloud',
  importName: 'CloudIcon',
  component: <CloudIcon aria-label="Cloud Icon" />,
  tags: 'cloud',
};
