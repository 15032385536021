// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const FrameworkIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 16 17" {...props} ref={ref} fill="none">
      <g id="Component 284" clipPath="url(#clip0_2248_8073)">
        <path
          id="Vector"
          d="M14.9488 2.73927C14.92 2.53298 14.7716 2.36329 14.571 2.30751L8.14028 0.519137C8.04861 0.493621 7.95177 0.493621 7.86002 0.519137L1.42935 2.30751C1.2287 2.36329 1.08028 2.53291 1.05155 2.73927C1.01425 3.00747 0.163361 9.34444 2.34584 12.4969C4.52575 15.6456 7.74157 16.4525 7.87738 16.4853C7.91775 16.4951 7.95888 16.4999 8.00015 16.4999C8.04143 16.4999 8.08256 16.495 8.12292 16.4853C8.2588 16.4525 11.4746 15.6456 13.6545 12.4969C15.8369 9.34451 14.9861 3.00754 14.9488 2.73927ZM12.1471 6.43886L7.76075 10.8252C7.65868 10.9273 7.52482 10.9784 7.39104 10.9784C7.25725 10.9784 7.1234 10.9274 7.02133 10.8252L4.30928 8.11318C4.21119 8.01515 4.15611 7.88213 4.15611 7.74347C4.15611 7.6048 4.21126 7.47178 4.30928 7.37376L4.84777 6.83527C5.05197 6.63114 5.38306 6.63107 5.58719 6.83527L7.39104 8.63912L10.8692 5.16088C10.9672 5.06279 11.1002 5.00771 11.2389 5.00771C11.3776 5.00771 11.5106 5.06279 11.6086 5.16088L12.1471 5.69937C12.3513 5.90357 12.3513 6.23466 12.1471 6.43886Z"
          fill="currentColor"
        />
      </g>

      <defs>
        <clipPath id="clip0_2248_8073">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const FrameworkIconMetadata = {
  name: 'Framework',
  importName: 'FrameworkIcon',
  component: <FrameworkIcon aria-label="Framework Icon" />,
  tags: 'framework, shield, verify, check, security, safe, secure, protect, guard, verified',
};
