// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const GitlabIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 32 32" {...props} ref={ref}>
      <path
        d="M29.5 13.2V13.1L25.7 3.20002C25.6 3.00002 25.5 2.80002 25.3 2.70002C24.9 2.50002 24.5 2.50002 24.1 2.80002C24 2.90002 23.8 3.00002 23.8 3.20002L21.2 11.1H10.8L8.20003 3.20002C8.20003 3.00002 8.00003 2.90002 7.90003 2.70002C7.50003 2.50002 7.10003 2.40002 6.70003 2.70002C6.50003 2.80002 6.40003 2.90002 6.30003 3.10002L2.50003 13V13.1C1.40003 16 2.30003 19.4 4.80003 21.3L10.6 25.6L13.5 27.8L15.2 29.1C15.6 29.4 16.2 29.4 16.6 29.1L18.3 27.8L21.2 25.6L27 21.2C29.7 19.5 30.7 16.1 29.5 13.2Z"
        fill="#E24329"
      />

      <path
        d="M29.5 13.2V13.1C27.6 13.5 25.9 14.3 24.4 15.4L16 21.7C18.9 23.9 21.3 25.7 21.3 25.7L27.1 21.3C29.7 19.5 30.7 16.1 29.5 13.2Z"
        fill="#FC6D26"
      />

      <path
        d="M10.7 25.8L13.6 28L15.3 29.3C15.7 29.6 16.3 29.6 16.7 29.3L18.4 28L21.3 25.8C21.3 25.8 18.8 23.9 16 21.8C13.1 23.9 10.7 25.8 10.7 25.8Z"
        fill="#FCA326"
      />

      <path
        d="M7.60003 15.4C6.10003 14.3 4.30003 13.5 2.50003 13.1V13.2C1.40003 16.1 2.30003 19.5 4.80003 21.4L10.6 25.7C10.6 25.7 13.1 23.8 15.9 21.7L7.60003 15.4Z"
        fill="#FC6D26"
      />
    </Icon>
  );
});

export const GitlabIconMetadata = {
  name: 'Gitlab',
  importName: 'GitlabIcon',
  component: <GitlabIcon aria-label="Gitlab Icon" />,
  tags: 'git, gitlab, code, repo, repository, logo, brand, company, org, organization, version, control, vc',
};
