// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const JiraTaskIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="#4BADE8"
        fillRule="evenodd"
        d="M0,1.99406028 C0,0.892771196 0.894513756,0 1.99406028,0 L14.0059397,0 C15.1072288,0 16,0.894513756 16,1.99406028 L16,14.0059397 C16,15.1072288 15.1054862,16 14.0059397,16 L1.99406028,16 C0.892771196,16 0,15.1054862 0,14.0059397 L0,1.99406028 Z M4.66666667,3 C3.74619208,3 3,3.74619208 3,4.66666667 L3,11.3333333 C3,12.2538079 3.74619208,13 4.66666667,13 L11.3333333,13 C12.2538079,13 13,12.2538079 13,11.3333333 L13,4.66666667 C13,3.74619208 12.2538079,3 11.3333333,3 L4.66666667,3 Z M5,5 L5,11 L11,11 L11,5 L5,5 Z"
      />
    </Icon>
  );
});

export const JiraTaskIconMetadata = {
  name: 'Jira Task',
  importName: 'JiraTaskIcon',
  component: <JiraTaskIcon aria-label="Jira Task Icon" />,
  tags: 'jira, task, issue, type, crm, ticket, atlassian, logo, brand, company, org, organization',
};
