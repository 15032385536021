// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '../icon';

// App - Other
import { Icon } from '../icon';

export const CommitIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 12 12" {...props} ref={ref} fill="none">
      <g>
        <path
          d="M7.9999 6C7.9999 7.10457 7.10447 8 5.9999 8C4.89533 8 3.9999 7.10457 3.9999 6M7.9999 6C7.9999 4.89543 7.10447 4 5.9999 4C4.89533 4 3.9999 4.89543 3.9999 6M7.9999 6H10.9999M3.9999 6H1"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Icon>
  );
});

export const CommitIconMetadata = {
  name: 'Commit Icon',
  importName: 'CommitIcon',
  component: <CommitIcon aria-label="Commit Icon" />,
  tags: 'commit, github, git, code, version, control, system, vcs',
};
